// @flow

import * as React from 'react';

import queryMap from './queries';
import { Context as I18nContext } from '_helpers/I18n';

// -------------------------------------------------------------------------------------------------

export type QueryProps = {|
  children: ({ loading: boolean, error: *, data: *, retry: ?(*) => void }) => React.Node,
  name: $Keys<typeof queryMap>,
  variables?: { [string]: * }
|};

// -------------------------------------------------------------------------------------------------

export default function Query(props: QueryProps): React.Node {
  const { name, children, variables } = props;
  const Q = queryMap[name];

  if (!Q) {
    throw new Error(`Invalid query name "${name}"`);
  }

  return (
    <Q variables={variables || {}}>
      {({ retry, error, props }) => (
        <LocaleTracker retry={retry}>
          {children({ loading: !retry, error, data: props, retry })}
        </LocaleTracker>
      )}
    </Q>
  );
}

// -------------------------------------------------------------------------------------------------

export function LocaleTracker(props: { children: *, retry: ?() => void }): React.Node {
  const { children, retry } = props;
  const { locale } = React.useContext(I18nContext);

  React.useEffect(() => {
    retry && retry();
  }, [locale]);

  return children;
}
