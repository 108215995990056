// @flow

import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { graphql, QueryRenderer } from 'react-relay';
import I18n from '_helpers/I18n';
import environment from '../../../../_helpers/Api/relayEnvironment';
import DealListItem from '../DealListItem';
import LoadingContent from '../../LoadingContent';

interface SuggestedProps {
  show: boolean;
  onClose: any;
  dealId: any;
}

const renderDataTable = (result: { error: any, props: any }) => {
  const { error, props } = result;

  if (error) {
    return (
      <p>
        <I18n
          d="Ooops! Something went wrong, try to change search criteria or reload page."
          id="errMessage"
        />
      </p>
    );
  } else if (props) {
    /*eslint-disable */
    const { cooperationDeals } = props;
    /*eslint-enable*/

    if (cooperationDeals && cooperationDeals.length === 0) {
      return <NoResult />;
    }

    if (cooperationDeals !== undefined && cooperationDeals !== null) {
      const items = cooperationDeals.map((edge, key) => (
        <DealListItem editMode={false} key={key} deal={edge} />
      ));
      return <W>{items}</W>;
    }
  }
  return <LoadingContent />;
};

const NoResult = () => {
  return (
    <I18n
      d="No opportunities were found. Please change your search criteria and try again"
      id="noResultsPlaceholder"
    />
  );
};

const W = (props: { children: React.Node }): React.Node => (
  <div className="deal-search-results">{props.children}</div>
);

const SuggestedModal = (props: SuggestedProps) => {
  return (
    <Modal show={props.show} onHide={props.onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <I18n id="suggestedDeals" d="Suggested deals" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: 'calc(100vh - 152px - 3.5rem)',
          overflow: 'auto'
        }}
      >
        <QueryRenderer
          key={'key'}
          variables={{
            dealId: props.dealId
          }}
          environment={environment}
          render={renderDataTable}
          query={suggestedQuery}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          <I18n id="closeBtn" d="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// -------------------------------------------------------------------------------------------------

const suggestedQuery = graphql`
  query SuggestedModalQuery($dealId: ID!) {
    cooperationDeals(dealId: $dealId) {
      id
      summary
      type
      cooperation
      status
      category {
        id
        name
      }
      keywords
      created
      description
    }
  }
`;

export default SuggestedModal;
