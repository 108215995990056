// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Icon from '../../../_components/Icon';
import Link from '../../../_components/Link';
import BBCode from '../../../_components/BBCode';

// more introduction info
export default function Section2(): React.Node {
  return (
    <Row className="home-s2" id="why-b3b">
      <Col xs={12} md={6}>
        <Card className="p-5 mb-5 dark">
          <h2>
            <I18n id="whyB3BTitle" d="Why B3B" />
          </h2>

          <div>
            <I18n
              d="As the world moves toward a circular economy, shared production and procurement processes are replacing the traditional way of doing business.[ul][li]Improve utilization of resources[/li][li]Lower costs of production[/li][li]Increase revenue and profit[/li][li]Make your company more resilient[/li][/ul]"
              id="whyB3BText"
            >
              {({ value }) => <BBCode>{value}</BBCode>}
            </I18n>
          </div>
        </Card>
      </Col>

      <Col xs={12} md={6}>
        <Card className="p-5 p-md-5">
          <h2>
            <I18n id="successStoryTitle" d="Success story" />
          </h2>
          <div>
            <p className="text-muted">
              <I18n
                d="Production sharing agreements can be beneficial to governments of countries that lack the expertise and/or capital to develop their resources and wish to attract foreign companies to do so. They can be very profitable agreements for the oil companies involved, but often involve considerable risk."
                id="successStoryText"
              />
            </p>
            <Link to="/success-story" className="d-block mt-5">
              <Icon name="page" className="mr-2" />
              <I18n id="successStoryMore" d="Read more" />
            </Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
