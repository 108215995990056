// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import type { CooperationType } from '../../../_components/Enum/Cooperation';

import BBCode from '_components/BBCode';
import Link from '../../../_components/Link';
import Icon from '../../../_components/Icon';
import CooperationTitle from '../../_components/Content/CooperationTitle';
import CooperationDescriptions from '../../_components/Content/CooperationDescriptions';

// -------------------------------------------------------------------------------------------------

// Areas of cooperation
export default function Section3(): React.Node {
  return (
    <div className="home-s-3" id="cooperation-areas">
      <div className="d-flex flex-column p5 mb-5">
        <h2 className="page-heading">
          <I18n id="title" d='Cooperation [span class="text-primary"]areas[/span]'>
            {({ value }) => <BBCode>{value}</BBCode>}
          </I18n>
        </h2>
        <span className="text-muted">
          <I18n id="subtitle" d="Some more text explaining cooperation" />
        </span>
      </div>
      <Row className="d-flex">
        <TypeCard type="SPARE_CAPACITY" />
        <TypeCard type="SHARE_ASSETS" />
      </Row>
      <Row className="d-flex">
        <TypeCard type="SHARE_PROCUREMENT" />
        <TypeCard type="RND_COOPERATION" />
      </Row>
      <Row />
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

function TypeCard(props: { type: CooperationType }): React.Node {
  const type = props.type;
  const Title = CooperationTitle[type];
  const Desc = CooperationDescriptions[type];
  const url = '/content/cooperation/' + type.toLowerCase().replace(/_/g, '-');
  return (
    <Col xs={12} md={6} className="cooperation-type mb-5">
      <div
        className="d-flex flex-column justify-content-between align-items-start"
        style={{ height: '100%' }}
      >
        <div>
          <Icon name={type} className="d-block mb-4" size={80} />

          <h4 className="mb-4 d-flex align-items-center">
            <Title />
          </h4>

          <p className="text-muted">
            <Desc />
          </p>
        </div>
        <Link component={Button} to={url} size="sm">
          <I18n id="learnModeButton" d="Learn more" />
        </Link>
      </div>
    </Col>
  );
}
