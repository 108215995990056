// @flow

import * as React from 'react';

import { setLocaleSource, getActiveLocale } from './I18n';
import { setLocaleHeader } from '../Api/relayEnvironment';

// -------------------------------------------------------------------------------------------------

const defaultLocale = localStorage.getItem('locale') || 'en';

setLocaleHeader(defaultLocale);

export type TempLocaleContextValue = {
  setLocale: string => void,
  locale: string
};

const Context = React.createContext<TempLocaleContextValue>({
  locale: defaultLocale,
  setLocale: () => undefined
});

export default Context; // its temporary bcs we are waiting new i18n package version

// -------------------------------------------------------------------------------------------------

export function Provider(props: { children: boolean => React.Node }): React.Node {
  const [{ locale, loading }, set] = React.useState({ locale: defaultLocale, loading: false });
  const activeLocale = getActiveLocale();

  const setLocale = React.useCallback(
    (newLocale: string) => {
      set({ loading: true, locale });
      setLocaleSource(newLocale).then(locale => {
        set({ locale, loading: false });
        setLocaleHeader(locale);
        localStorage.setItem('locale', locale);
      });
    },
    [set]
  );

  React.useEffect(() => {
    activeLocale !== locale && !loading && setLocale(locale);
  }, [locale, activeLocale, loading, setLocale]);

  return (
    <Context.Provider value={{ locale, setLocale }}>{props.children(loading)}</Context.Provider>
  );
}
