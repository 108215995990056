// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import Layout from '../../_components/Layout';
import Redirect from 'react-router-dom/Redirect';
import { getActiveLocale } from '_helpers/I18n/I18n';
import Form, { PasswordInput, TextInput } from '../../_components/Form';
import { Context as IdentityContext } from '_helpers/Identity';
import { routerHistory } from '../../_helpers/router';

// -------------------------------------------------------------------------------------------------

const emailValidation = {
  newEmail: { required: true, email: true }
};

const resetValidation = {
  password: { required: true, minLength: 8, hasDigit: true },
  newPassword: { required: true, minLength: 8, hasDigit: true },
  passwordConfirmation: { required: true }
};

export default function PasswordResetPage(props: *): React.Node {
  const { identity, refreshIdentity } = React.useContext(IdentityContext);
  const [pending, setPending] = React.useState(false);

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/" />;
    }
  }, []);

  return (
    <Layout.Common narrow>
      <h1 className="mt-4 mb-4">
        <I18n id="emailTitle" d="Email reset" />
      </h1>
      <Form
        onSubmit={handleSubmit('email', setPending)}
        validationRules={emailValidation}
        disabled={pending}
      >
        <Row className="align-items-center">
          <Col className="min-width-300">
            <BForm.Group>
              <BForm.Label>
                <I18n id="newEmailLabel" d="Login e-mail address" />
                <span className="form-label-required"> *</span>
              </BForm.Label>
              <I18n id="newEmailPlaceholder" d="Login e-mail address">
                {placeholder => (
                  <Form.Field
                    placeholder={placeholder.value}
                    component={TextInput}
                    name="newEmail"
                  />
                )}
              </I18n>
              <div className="mt-2 text-small">
                <I18n id="loginEmailDescription" d="This e-mail is used for platform login" />
              </div>
            </BForm.Group>
          </Col>
          <Col className="mb-3 min-width-300">
            <Button variant="primary" block type="submit" disabled={pending}>
              <I18n id="emailSubmit" d="Change login e-mail" />
            </Button>
          </Col>
        </Row>
      </Form>
      <h1 className="mt-4 mb-4">
        <I18n id="passwordTitle" d="Password reset" />
      </h1>
      <Form
        onSubmit={handleSubmit('password', setPending)}
        validationRules={resetValidation}
        disabled={pending}
      >
        <Row>
          <Col>
            <BForm.Group>
              <BForm.Label>
                <I18n id="oldPasswordLabel" d="Old password" />
                <span className="form-label-required"> *</span>
              </BForm.Label>
              <I18n id="oldPasswordPlaceholder" d="Old password">
                {placeholder => (
                  <Form.Field
                    placeholder={placeholder.value}
                    component={PasswordInput}
                    name="password"
                    autoComplete="off"
                  />
                )}
              </I18n>
            </BForm.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <BForm.Group>
              <BForm.Label>
                <I18n id="newPasswordLabel" d="New password" />
                <span className="form-label-required"> *</span>
              </BForm.Label>
              <I18n id="newPasswordPlaceholder" d="New password">
                {placeholder => (
                  <Form.Field
                    placeholder={placeholder.value}
                    component={PasswordInput}
                    name="newPassword"
                    autoComplete="off"
                  />
                )}
              </I18n>
            </BForm.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <BForm.Group>
              <BForm.Label>
                <I18n id="confirmPasswordLabel" d="Confirm password" />
                <span className="form-label-required"> *</span>
              </BForm.Label>
              <I18n id="confirmPasswordPlaceholder" d="Confirm new password">
                {placeholder => (
                  <Form.Field
                    placeholder={placeholder.value}
                    component={PasswordInput}
                    name="passwordConfirmation"
                    autoComplete="off"
                  />
                )}
              </I18n>
            </BForm.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button variant="primary" block type="submit" disabled={pending}>
              <I18n id="submit" d="Save" />
            </Button>
          </Col>
          <Col>
            <Link component={Button} to="/account" variant="outline-dark" block disabled={pending}>
              <I18n id="cancel" d="Cancel" />
            </Link>
          </Col>
        </Row>
      </Form>
    </Layout.Common>
  );
}

// handle submit action
const handleSubmit = (action: any, setPending: boolean => void) => (
  data: any,
  form: { reset: () => void }
) => {
  if (!action) {
    return;
  }
  setPending(true);

  const config = {
    method: 'POST',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json',
      'Accept-Language': getActiveLocale()
    },
    body: JSON.stringify(data),
    credentials: 'include',
    cache: 'no-cache'
  };
  if (action === 'email') {
    fetch(process.env.REACT_APP_API_HOST + '/auth/changeEmail', config)
      .then(function(response) {
        if (!response.ok) {
          if (response.status === 422 && response.statusText === 'Unprocessable Entity') {
            setPending(false);
            toastr({
              type: 'error',
              message: <I18n d="This e-mail is already used!" id="emailTakenError" />
            });
          } else {
            setPending(false);
            toastr({
              type: 'error',
              message: <I18n d="Login e-mail has not been changed" id="emailChangeError" />
            });
          }
          throw Error(response.statusText);
        }
      })
      .then(function(response) {
        setPending(false);
        toastr({
          type: 'success',
          message: <I18n d="Login e-mail has been successfully changed" id="emailChangeSuccess" />
        });
        form.reset();
        routerHistory.push('/account');
      })
      .catch(function(error) {
        setPending(false);
        toastr({
          type: 'error',
          message: <I18n d="Login e-mail has not been changed" id="emailChangeError" />
        });
      });
  } else {
    fetch(process.env.REACT_APP_API_HOST + '/auth/changePassword', config)
      .then(function(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(function(response) {
        setPending(false);
        toastr({
          type: 'success',
          message: <I18n d="Password has been successfully changed" id="passwordChangeSuccess" />
        });
        form.reset();
        routerHistory.push('/account');
      })
      .catch(function(error) {
        setPending(false);
        toastr({
          type: 'error',
          message: <I18n d="Password has not been changed" id="passwordChangeError" />
        });
      });
  }
};
