// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';

import Icon from '../../../../_components/Icon';
import Link from '../../../../_components/Link';
import { Context as CloseDealModalContext } from './CloseDealModal';
import { Context as DeleteDealModalContext } from './DeleteDealModal';

export type DealModalTarget = { id: ID, summary: string };

export default function EditModeButtons(props: {
  deal: { id: ID, status: DealStatus, summary: string }
}): React.Node {
  const { deal } = props;
  return (
    <div className="d-inline-block">
      <I18n id="edit" d="Edit deal">
        {title => (
          <Link to={`/deal/edit/${deal.id}`} className="ml-2" title={title.value}>
            <Icon name="clipboard-edit" />
          </Link>
        )}
      </I18n>

      <I18n id="manage" d="Manage deal Replies">
        {title => (
          <Link to={`/deal/manage/${deal.id}`} className="ml-2" title={title.value}>
            <Icon name="clipboard-list" />
          </Link>
        )}
      </I18n>

      {deal.status !== 'CLOSED' && (
        <I18n id="closeDeal" d="Close Opportunity">
          {title => (
            <CloseDealModalContext.Consumer>
              {({ setTarget }) => (
                <Link onClick={setDeal(setTarget, deal)} className="ml-2" title={title.value}>
                  <Icon name="close" />
                </Link>
              )}
            </CloseDealModalContext.Consumer>
          )}
        </I18n>
      )}

      <I18n id="trash" d="Delete Opportunity">
        {title => (
          <DeleteDealModalContext.Consumer>
            {({ setTarget }) => (
              <Link onClick={setDeal(setTarget, deal)} className="ml-2" title={title.value}>
                <Icon name="trash-can" />
              </Link>
            )}
          </DeleteDealModalContext.Consumer>
        )}
      </I18n>
    </div>
  );
}

function setDeal(setter: DealModalTarget => void, target: DealModalTarget): () => void {
  return function(): void {
    setter && target && setter(target);
  };
}
