// @flow

import { getEndpoint } from './';

export type ResetVariables = {|
  password: string,
  token: string
|};

export default function reset(variables: ResetVariables): Promise<*> {
  return fetch(getEndpoint() + '/auth/reset', {
    method: 'POST',
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json'
    },
    body: JSON.stringify(variables),
    credentials: 'include',
    cache: 'no-cache'
  })
    .then(response => response.json())
    .then(response => {
      if (response.ok) {
        return fetch(getEndpoint() + '/auth/me', {
          method: 'GET',
          headers: {
            'x-requested-with': 'XMLHttpRequest',
            'content-type': 'application/json'
          },
          cache: 'no-cache',
          credentials: 'include'
        }).then(response => response.json());
      }
      return response;
    })
    .catch(error => ({
      ok: false,
      error
    }));
}
