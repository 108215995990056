// @flow
import I18n from '_helpers/I18n';
import * as React from 'react';

import type { FieldError } from './types';

// -------------------------------------------------------------------------------------------------

export type ValidationMessageProps = { error: FieldError };

// -------------------------------------------------------------------------------------------------

export default class ValidationMessage extends React.PureComponent<ValidationMessageProps> {
  render(): React.Node {
    let options: void | $PropertyType<FieldError, 'options'> = undefined;

    const error: null | FieldError = this.props.error;

    if (error) {
      if (error.options && typeof error.options === 'object') {
        options = error.options;
      } else if (
        ['number', 'string'].indexOf(typeof error.options) > -1 ||
        error.options === null
      ) {
        let current = 0;
        try {
          current = error.value.length;
        } catch (e) {
          current = 0;
        }
        options = { [error.code]: error.options, current };
      }

      return (
        error &&
        error.code && (
          <I18n
            {...options}
            d={{
              hasUppercase: 'Value must contains 1 Uppecase character atleast',
              linkedIn: 'url must starts with https://(www.)linkedin.com/',
              maxCount: 'You reached max number of items ({maxCount})',
              maxLength: 'Max {maxLength} characters ({current})',
              minLength: 'Min {minLength} characters ({current})',
              notSame: 'Entered passwords are not same',
              web: 'Entered value does not look like valid url',
              vatNumber: 'Value looks like invalid vat number',
              hasDigit: 'Value must contains 1 digit atleast',
              laterThan: 'Value has to be after {label}',
              digitsOnly: 'Digits only allowed',
              phone: 'Invalid phone number',
              required: 'Field is required',
              number: 'Invalid number',
              zip: 'Invalid zip code',
              email: 'Invalid email',
              max: 'Max {max}',
              min: 'Min {min}'
            }}
            id="validationMessage$"
            v={error.code}
          />
        )
      );
    }
    return null;
  }
}
