// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import Icon from './Icon';
import { Context as I18nContext } from '../_helpers/I18n';
import { Context as IdentityContext } from '../_helpers/Identity';
import rememberLocaleMutation from '_helpers/Api/rememberLocaleMutation';

export default function LocaleSwitch(): React.Node {
  const { locale, setLocale } = React.useContext(I18nContext);
  const { identity } = React.useContext(IdentityContext);

  const setter = React.useCallback(
    (locale: string) => {
      return () => {
        setLocale(locale);
        if (identity) {
          rememberLocaleMutation(locale.toUpperCase());
        }
      };
    },
    [setLocale, identity]
  );

  return (
    <DropdownButton
      className="d-flex locale-switch"
      title={
        <>
          <Locale v={locale} />
          <Icon name="caret-down" />
        </>
      }
      value={locale}
    >
      <Locale>
        {({ d }) =>
          Object.keys(d).map(locale => (
            <Dropdown.Item key={locale} onClick={setter(locale)}>
              {d[locale]}
            </Dropdown.Item>
          ))
        }
      </Locale>
    </DropdownButton>
  );
}
const Locale = (props: any) => {
  return <I18n {...props} id="labels$" d={{ sk: 'SK', en: 'EN', cz: 'CZ' }} />;
};
