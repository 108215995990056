import * as React from 'react';
import I18n from '_helpers/I18n';

export default {
  anonym: [
    {
      label: <I18n id="homeBtn" d="Home" />,
      path: '/#'
    },
    {
      label: <I18n id="cooperationBtn" d="Partnership Areas" />,
      path: '/#cooperation-areas'
    },
    {
      label: <I18n id="howItWorksBtn" d="How It Works" />,
      path: '/#how-it-works'
    },
    {
      label: <I18n id="pricingBtn" d="Pricing" />,
      path: '/#pricing'
    }
  ],

  user: [
    {
      label: <I18n id="searchDealsBtn" d="Search Opportunities" />,
      path: '/deal'
    },
    {
      label: <I18n id="createDealBtn" d="Create Opportunity" />,
      path: '/deal/new'
    },
    {
      label: <I18n id="myDealsBtn" d="My Opportunities" />,
      path: '/account/my-deals'
    },
    {
      label: <I18n id="myContractsBtn" d="My Replies" />,
      path: '/account/my-contracts'
    }
  ],
  profile: [
    {
      label: <I18n id="myProfile" d="My Profile" />,
      path: '/account/profile'
    },
    {
      label: <I18n id="myAccount" d="My Account" />,
      path: '/account'
    },
    {
      label: <I18n id="myCompany" d="My Company" />,
      path: '/account/company'
    },
    {
      label: <I18n id="logout" d="Logout" />,
      path: '/auth/logout'
    }
  ]
};
