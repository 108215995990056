// @flow

import cn from 'classnames';
import * as React from 'react';
import I18n from '_helpers/I18n';
import TextArea from 'react-autosize-textarea';

import BBCode from '../../BBCode';
import type { OnChangeEvent } from '../';

export type TextareaInputProps = {
  onChange: (OnChangeEvent<string>) => void,
  validationRules?: { [string]: any },
  value: null | string,
  isInvalid?: boolean,
  className?: string,
  name: string
};

export default function TextareaInput(props: TextareaInputProps): React.Node {
  const { onChange, isInvalid, validationRules, value, name, className, ...rest } = props;

  // $FlowFixMe
  delete rest.isValid;
  // $FlowFixMe
  delete rest.batchChange;

  // eslint-disable-next-line react/prop-types
  const max = validationRules && validationRules.maxLength;

  return (
    <>
      <TextArea
        rows={3}
        {...rest}
        className={cn('form-control', { 'is-invalid': isInvalid }, className || '')}
        onChange={changeHandler(name, onChange, max)}
        value={value || ''}
      />
      {max && (
        <div className="mt-2 text-small">
          <I18n
            d="{max} maximum characters, [b]{left} left[/b]"
            left={max - (value || '').length}
            id="maxChars"
            c={BBCode}
            max={max}
          >
            {text => <BBCode>{text.value}</BBCode>}
          </I18n>
        </div>
      )}
    </>
  );
}

function changeHandler(
  name: string,
  onChange: (OnChangeEvent<string>) => void,
  max?: number
): (SyntheticEvent<HTMLTextAreaElement>) => void {
  return function(e: SyntheticEvent<HTMLTextAreaElement>): void {
    const value: null | string = e.currentTarget.value || null;
    onChange({ value: value && max ? value.substr(0, max) : value, name });
  };
}
