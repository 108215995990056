/**
 * @flow
 * @relayHash b7b57c1ee620ab156e147939804839df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type myCompanyQueryVariables = {||};
export type myCompanyQueryResponse = {|
  +me: ?{|
    +id: string,
    +company: {|
      +id: string,
      +name: ?string,
      +vat: ?string,
      +logo: ?{|
        +id: string,
        +name: string,
        +url: string,
        +size: number,
      |},
      +info: ?string,
      +web: ?string,
      +specialization: ?string,
      +certificates: ?string,
      +categories: $ReadOnlyArray<{|
        +id: string,
        +name: string,
      |}>,
      +country: ?any,
      +state: ?string,
      +street: ?string,
      +city: ?string,
      +zip: ?string,
      +gps: ?{|
        +lng: number,
        +lat: number,
      |},
    |},
  |}
|};
export type myCompanyQuery = {|
  variables: myCompanyQueryVariables,
  response: myCompanyQueryResponse,
|};
*/

/*
query myCompanyQuery {
  me {
    id
    company {
      id
      name
      vat
      logo {
        id
        name
        url
        size
      }
      info
      web
      specialization
      certificates
      categories {
        id
        name
      }
      country
      state
      street
      city
      zip
      gps {
        lng
        lat
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null
    },
    v2 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'me',
        storageKey: null,
        args: null,
        concreteType: 'User',
        plural: false,
        selections: [
          (v0 /*: any*/),
          {
            kind: 'LinkedField',
            alias: null,
            name: 'company',
            storageKey: null,
            args: null,
            concreteType: 'Company',
            plural: false,
            selections: [
              (v0 /*: any*/),
              (v1 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'vat',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'logo',
                storageKey: null,
                args: null,
                concreteType: 'File',
                plural: false,
                selections: [
                  (v0 /*: any*/),
                  (v1 /*: any*/),
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'url',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'size',
                    args: null,
                    storageKey: null
                  }
                ]
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'info',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'web',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'specialization',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'certificates',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'categories',
                storageKey: null,
                args: null,
                concreteType: 'NACECategory',
                plural: true,
                selections: [(v0 /*: any*/), (v1 /*: any*/)]
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'country',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'state',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'street',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'city',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'zip',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'gps',
                storageKey: null,
                args: null,
                concreteType: 'Gps',
                plural: false,
                selections: [
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'lng',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'lat',
                    args: null,
                    storageKey: null
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'myCompanyQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: (v2 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'myCompanyQuery',
      argumentDefinitions: [],
      selections: (v2 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'myCompanyQuery',
      id: null,
      text:
        'query myCompanyQuery {\n  me {\n    id\n    company {\n      id\n      name\n      vat\n      logo {\n        id\n        name\n        url\n        size\n      }\n      info\n      web\n      specialization\n      certificates\n      categories {\n        id\n        name\n      }\n      country\n      state\n      street\n      city\n      zip\n      gps {\n        lng\n        lat\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '78ff8b9a1e0d09b2cd62b07d861d81f6';
module.exports = node;
