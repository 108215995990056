// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import queryString from 'query-string';
import { getActiveLocale } from '_helpers/I18n/I18n';
import { routerHistory } from '../../_helpers/router';
import toastr from '../../_helpers/toastr';
import { Context as I18nContext } from '../../_helpers/I18n';

// -------------------------------------------------------------------------------------------------

export default function VerifiedPage(props: *): React.Node {
  const values = queryString.parse(props.location.search);
  const { setLocale } = React.useContext(I18nContext);

  React.useEffect(() => {
    if (getActiveLocale() && getActiveLocale() !== values.lang.toLowerCase()) {
      setLocale(values.lang.toLowerCase());
    }
    if (getActiveLocale() && getActiveLocale() === values.lang.toLowerCase()) {
      toastr({
        message: <I18n id="emailHasBeenVerified" d="Great! Your email has been verified." />,
        type: 'success'
      });
      routerHistory.push('/auth/login');
    }
  });

  return null;
}
