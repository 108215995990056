// @flow

import idx from 'idx';
import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Link from '../../_components/Link';
import Card from 'react-bootstrap/Card';

import { Query } from '_helpers/Api';
import BBCode from '../../_components/BBCode';
import Layout from '../../_components/Layout';
import { Context as Identity } from '_helpers/Identity';
import LoadingContent from '../_components/LoadingContent';
import history from '../../_helpers/router/routerHistory';

export default function PaymentSuccess(): React.Node {
  const { loading: refreshing, identity, updateIdentity } = React.useContext(Identity);
  const [oldIdentity, setIdentity] = React.useState(null);
  const dealURL = localStorage.getItem('contactURL') ? localStorage.getItem('contactURL') : '/deal';

  React.useEffect(() => {
    if (refreshing && !oldIdentity) {
      setIdentity(identity);
    } else if (!refreshing && oldIdentity) {
      if (identity.paymentInProgress) {
        updateIdentity({ ...identity, paymentInProgress: false });
      }
      if (idx(identity, _ => _.company.paying) === idx(oldIdentity, _ => _.company.paying)) {
        if (idx(identity, _ => _.company.paying) === false) {
          return history.push('/subscribe');
        }
        return history.push('/account');
      }
    }
  }, [refreshing]);

  return (
    <Layout verticalCenter>
      <Row className="mt-5 mb-5 d-flex justify-content-center align-items-center">
        <Col lg={4} sm={8} xs={12} className="text-center">
          {refreshing ? (
            <LoadingContent />
          ) : (
            <Query name="me">
              {({ loading }) =>
                loading || refreshing ? (
                  <LoadingContent />
                ) : (
                  <Card className="p-5">
                    <h1 className="mb-4 mt-4">
                      <I18n id="title" d="Well done!" />
                    </h1>
                    <div className="text-center">
                      <I18n
                        d='Your payment has been[span class="p-1 bg-success"]confirmed[/span][br /][h1 class="mb-4 mt-4"]Thank you![/h1]'
                        id="message"
                      >
                        {({ value }) => <BBCode>{value}</BBCode>}
                      </I18n>
                    </div>
                    <Link
                      to={dealURL}
                      component={Button}
                      variant="primary"
                      className="text-center mt-3"
                    >
                      <I18n d="Continue" id="moreText">
                        {({ value }) => <BBCode>{value}</BBCode>}
                      </I18n>
                    </Link>
                  </Card>
                )
              }
            </Query>
          )}
        </Col>
      </Row>
    </Layout>
  );
}
