/**
 * @flow
 * @relayHash 1b0ef046d4da3dc70849151a15895649
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type manageDealQueryVariables = {|
  dealId: string
|};
export type manageDealQueryResponse = {|
  +deal: ?{|
    +id: string,
    +summary: string,
    +contracts: {|
      +edges: $ReadOnlyArray<{|
        +cursor: number,
        +node: {|
          +id: string,
          +seen: boolean,
          +status: ?ContractStatus,
          +consumer: {|
            +id: string,
            +name: ?string,
            +created: ?any,
            +totalContracts: number,
            +succeededContracts: number,
          |},
          +deal: {|
            +id: string,
            +summary: string,
          |},
        |},
      |}>,
      +total: number,
    |},
  |}
|};
export type manageDealQuery = {|
  variables: manageDealQueryVariables,
  response: manageDealQueryResponse,
|};
*/

/*
query manageDealQuery(
  $dealId: ID!
) {
  deal(id: $dealId) {
    id
    summary
    contracts(first: 100) {
      edges {
        cursor
        node {
          id
          seen
          status
          consumer {
            id
            name
            created
            totalContracts
            succeededContracts
          }
          deal {
            id
            summary
          }
        }
      }
      total
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'dealId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'summary',
      args: null,
      storageKey: null
    },
    v3 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'deal',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'dealId'
          }
        ],
        concreteType: 'Deal',
        plural: false,
        selections: [
          (v1 /*: any*/),
          (v2 /*: any*/),
          {
            kind: 'LinkedField',
            alias: null,
            name: 'contracts',
            storageKey: 'contracts(first:100)',
            args: [
              {
                kind: 'Literal',
                name: 'first',
                value: 100
              }
            ],
            concreteType: 'ContractsConnection',
            plural: false,
            selections: [
              {
                kind: 'LinkedField',
                alias: null,
                name: 'edges',
                storageKey: null,
                args: null,
                concreteType: 'ContractEdge',
                plural: true,
                selections: [
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'cursor',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'node',
                    storageKey: null,
                    args: null,
                    concreteType: 'Contract',
                    plural: false,
                    selections: [
                      (v1 /*: any*/),
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'seen',
                        args: null,
                        storageKey: null
                      },
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'status',
                        args: null,
                        storageKey: null
                      },
                      {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'consumer',
                        storageKey: null,
                        args: null,
                        concreteType: 'Company',
                        plural: false,
                        selections: [
                          (v1 /*: any*/),
                          {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'name',
                            args: null,
                            storageKey: null
                          },
                          {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'created',
                            args: null,
                            storageKey: null
                          },
                          {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'totalContracts',
                            args: null,
                            storageKey: null
                          },
                          {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'succeededContracts',
                            args: null,
                            storageKey: null
                          }
                        ]
                      },
                      {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'deal',
                        storageKey: null,
                        args: null,
                        concreteType: 'Deal',
                        plural: false,
                        selections: [(v1 /*: any*/), (v2 /*: any*/)]
                      }
                    ]
                  }
                ]
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'total',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'manageDealQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'manageDealQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'manageDealQuery',
      id: null,
      text:
        'query manageDealQuery(\n  $dealId: ID!\n) {\n  deal(id: $dealId) {\n    id\n    summary\n    contracts(first: 100) {\n      edges {\n        cursor\n        node {\n          id\n          seen\n          status\n          consumer {\n            id\n            name\n            created\n            totalContracts\n            succeededContracts\n          }\n          deal {\n            id\n            summary\n          }\n        }\n      }\n      total\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'd684fd9e3250c29f8238764682879780';
module.exports = node;
