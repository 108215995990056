// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SuggestedModal from '../DealList/_components/SuggestedModal';
import queryString from 'query-string';

import Enum from '../../../_components/Enum';
import Link from '../../../_components/Link';
import Icon from '../../../_components/Icon';
import DateTime from '../../../_components/DateTime';
import ExpandText from '../../../_components/ExpandText';
import EditModeButtons from './_components/EditModeButtons';

const DESC_LENGTH = 190;

// -------------------------------------------------------------------------------------------------

export type DealListItemProps = {|
  editMode?: boolean,
  deal: *
|};

export default function DealListItem(props: DealListItemProps): React.Node {
  const { deal, editMode } = props;
  const [mobile, setMobile] = window.innerWidth > 600 ? useState(false) : useState(true);
  const [suggestedModal, setSuggestedModal] = useState(false);

  function handleSuggestedClose() {
    setSuggestedModal(false);
  }

  function handleSuggestedShow() {
    setSuggestedModal(true);
  }

  const queryParam = queryString.parse(window.location.search);
  const animation = queryParam.suggested !== undefined && queryParam.suggested === 'true';

  const mobileSize = () => {
    if (window.innerWidth < 1200) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener('resize', mobileSize);

  if (!deal) {
    return null;
  }

  const categories = deal.category && deal.category.name;

  return (
    <div className="p-4 deal-search-item">
      <Row>
        <Col className="d-md-flex align-items-center justify-content-between">
          <Link
            to={editMode ? `/deal/edit/${deal.id}` : `/deal/detail/${deal.id}`}
            className="summary d-inline-flex"
          >
            <CooperationIcon value={deal.cooperation} />
            <div className="deal-list-item-h3">
              <h3 className="mb-2">{deal.summary}</h3>
              <small>
                <DateTime.Date value={deal.created} />
              </small>
            </div>
          </Link>
          <div className="float-none mt-3 mt-md-0 float-md-right d-flex align-items-center">
            <Badge variant="outline-secondary" className="ml-3">
              <Icon name={deal.type.toLowerCase()} className="mr-2" />
              <Enum.DealType v={deal.type} />
            </Badge>

            {editMode && (
              <>
                <span className="d-none d-md-inline-block">
                  <EditModeButtons deal={deal} />
                </span>

                <Badge variant={deal.status === 'OPEN' ? 'warning' : 'secondary'} className="ml-3">
                  <I18n id="status$" d={{ OPEN: 'Open', CLOSED: 'Closed' }} v={deal.status} />
                </Badge>

                <span
                  onClick={handleSuggestedShow}
                  className={`suggestedModalBtn ${animation ? 'animation' : ''} `}
                >
                  <Badge variant={'primary'} className="ml-2">
                    <I18n id="suggestedDeals" d="Suggested deals" />
                  </Badge>
                </span>
                <SuggestedModal
                  show={suggestedModal}
                  onClose={handleSuggestedClose}
                  dealId={deal.id}
                />

                {!mobile && deal.unseenContracts && deal.unseenContracts.total > 0 && (
                  <Link
                    to={`/deal/manage/${deal.id}`}
                    component={Badge}
                    variant="success"
                    className="ml-4"
                  >
                    <I18n
                      d="{count} New {count, plural, one {Contract} other {contracts}}"
                      count={deal.unseenContracts.total}
                      id="unseenContracts"
                    />
                  </Link>
                )}
              </>
            )}
          </div>
          {editMode && (
            <span className="d-flex d-md-none mt-2">
              <EditModeButtons deal={deal} />
              {mobile && deal.unseenContracts && deal.unseenContracts.total > 0 && (
                <Link
                  to={`/deal/manage/${deal.id}`}
                  component={Badge}
                  variant="success"
                  className="ml-4"
                >
                  <I18n
                    d="{count} New {count, plural, one {Contract} other {contracts}}"
                    count={deal.unseenContracts.total}
                    id="unseenContracts"
                  />
                </Link>
              )}
            </span>
          )}
        </Col>
      </Row>
      <Row className="d-flex mb-3">
        <Col className="mr-auto">
          <Keywords keywords={deal.keywords} myDeals={!!editMode} />
        </Col>
        <Col className="text-md-center text-xl-left" xs={12} sm={12} md={4} xl={3}>
          {editMode && deal.id && (
            <span className="mr-2 mr-xl-0 ml-xl-2">
              <Link to={`/deal/manage/${deal.id}`}>
                <Icon name="clipboard-list" className="mr-2" />
                <I18n id="manage" d="Business Partners" />
              </Link>
            </span>
          )}
        </Col>
        <Col
          className="text-md-right deal-search-item__categories"
          title={categories}
          xs={12}
          sm={12}
          md={5}
        >
          {categories}
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column text-justify font-weight-bold">
          <ExpandText text={deal.description || ''} length={DESC_LENGTH} />
        </Col>
      </Row>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

function CooperationIcon(props: { value: string }): React.Node {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <Enum.Cooperation v={props.value} />
        </Tooltip>
      }
    >
      <span>
        <Icon name={props.value} className=" mr-2" size={23} />
      </span>
    </OverlayTrigger>
  );
}

// -------------------------------------------------------------------------------------------------

function Keywords(props: { keywords: Array<string>, myDeals: boolean }): React.Node {
  return (
    <div className="deal-search-item__keywords d-flex flex-wrap">
      {props.keywords.reduce((list: Array<React.Node>, keyword: string, key: number) => {
        keyword &&
          list.push(
            <a
              href={`${props.myDeals ? '/account/my-deals' : '/deal'}?keywords=${keyword}`}
              className="mr-1"
              // TODO: key
              key={key}
            >
              #{keyword}
            </a>
          );
        return list;
      }, [])}
    </div>
  );
}
