// @flow

import type { DealFormData } from '../_components/DealForm';

import type { DealInput } from '../__generated__/NewDealPageMutation.graphql';

export function decorateDealInput(data: DealFormData): null | DealInput {
  const {
    partnerRequirements,
    keywords,
    description,
    cooperation,
    category,
    summary,
    expires,
    video,
    type
  } = data;

  if (!cooperation || !category || !summary || !type) {
    return null;
  }
  const expirationDate = new Date(expires);
  const expirationMonth =
    (expirationDate.getMonth() + 1).toString().length < 2
      ? '0' + (expirationDate.getMonth() + 1)
      : expirationDate.getMonth() + 1;
  const expirationDay =
    expirationDate.getDate().toString().length < 2
      ? '0' + expirationDate.getDate()
      : expirationDate.getDate();
  const expiresString = expirationDate.getFullYear() + '-' + expirationMonth + '-' + expirationDay;

  return {
    partnerRequirements: typeof partnerRequirements === 'string' ? partnerRequirements : null,
    description: typeof description === 'string' ? description : null,
    expires: expires ? expiresString : null,
    keywords: keywords || [],
    cooperation,
    summary,
    type,
    attachments: data && data.attachments ? data.attachments.reduce(reduceFiles, []) : null,
    category: category.id,
    video: video && !hasProtocol(video) ? 'http://' + video : video || null
  };
}

// -------------------------------------------------------------------------------------------------

const reduceFiles = (idList, file) => (file && file.id ? [...idList, `${file.id}`] : idList);
const hasProtocol = val =>
  typeof val === 'string' && (val.startsWith('http://') || val.startsWith('https://'));
