/**
 * @flow
 * @relayHash 7b38bc84d2723e47fe6b41ae0d81c604
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LangEnum = "CZ" | "EN" | "SK" | "%future added value";
export type rememberLocaleMutationVariables = {|
  locale: LangEnum
|};
export type rememberLocaleMutationResponse = {|
  +myLanguage: {|
    +id: string,
    +lang: ?LangEnum,
  |}
|};
export type rememberLocaleMutation = {|
  variables: rememberLocaleMutationVariables,
  response: rememberLocaleMutationResponse,
|};
*/

/*
mutation rememberLocaleMutation(
  $locale: LangEnum!
) {
  myLanguage(language: $locale) {
    id
    lang
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'locale',
        type: 'LangEnum!',
        defaultValue: null
      }
    ],
    v1 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'myLanguage',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'language',
            variableName: 'locale'
          }
        ],
        concreteType: 'User',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'lang',
            args: null,
            storageKey: null
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'rememberLocaleMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'rememberLocaleMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'rememberLocaleMutation',
      id: null,
      text:
        'mutation rememberLocaleMutation(\n  $locale: LangEnum!\n) {\n  myLanguage(language: $locale) {\n    id\n    lang\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'ceb5b383fdc72096907ce2aee81ad9c1';
module.exports = node;
