/**
 * @flow
 * @relayHash fc4daac4407352619d04f66082126ce9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type manageContractQueryVariables = {|
  contractId: string
|};
export type manageContractQueryResponse = {|
  +contract: ?{|
    +id: string,
    +status: ?ContractStatus,
    +notes: ?string,
    +created: ?any,
    +consumer: {|
      +id: string,
      +name: ?string,
    |},
    +deal: {|
      +id: string,
      +summary: string,
      +type: DealType,
      +cooperation: DealCooperation,
      +category: {|
        +id: string,
        +name: string,
      |},
    |},
  |}
|};
export type manageContractQuery = {|
  variables: manageContractQueryVariables,
  response: manageContractQueryResponse,
|};
*/

/*
query manageContractQuery(
  $contractId: ID!
) {
  contract(id: $contractId) {
    id
    status
    notes
    created
    consumer {
      id
      name
    }
    deal {
      id
      summary
      type
      cooperation
      category {
        id
        name
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'contractId',
        type: 'ID!',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = [
      (v1 /*: any*/),
      {
        kind: 'ScalarField',
        alias: null,
        name: 'name',
        args: null,
        storageKey: null
      }
    ],
    v3 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'contract',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'contractId'
          }
        ],
        concreteType: 'Contract',
        plural: false,
        selections: [
          (v1 /*: any*/),
          {
            kind: 'ScalarField',
            alias: null,
            name: 'status',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'notes',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'created',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'consumer',
            storageKey: null,
            args: null,
            concreteType: 'Company',
            plural: false,
            selections: (v2 /*: any*/)
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'deal',
            storageKey: null,
            args: null,
            concreteType: 'Deal',
            plural: false,
            selections: [
              (v1 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'summary',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'type',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'cooperation',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'category',
                storageKey: null,
                args: null,
                concreteType: 'NACECategory',
                plural: false,
                selections: (v2 /*: any*/)
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'manageContractQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'manageContractQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'manageContractQuery',
      id: null,
      text:
        'query manageContractQuery(\n  $contractId: ID!\n) {\n  contract(id: $contractId) {\n    id\n    status\n    notes\n    created\n    consumer {\n      id\n      name\n    }\n    deal {\n      id\n      summary\n      type\n      cooperation\n      category {\n        id\n        name\n      }\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'aa2708985f016ada57336862f8ab1a79';
module.exports = node;
