// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Badge from 'react-bootstrap/Badge';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Enum from '../../_components/Enum';

// -------------------------------------------------------------------------------------------------

// TODO: prop types
export default function DealStatusDropdown(props: *): React.Node {
  const { value, onChange, id, name } = props;
  return (
    <NavDropdown
      id={id}
      title={
        <span>
          <span className="text-secondary mr-3">
            <I18n id="label" d="Status" />
          </span>
          <span className="text-primary">
            {value ? <Enum.DealStatus v={value} /> : <AnyLabel />}
          </span>
        </span>
      }
    >
      <NavDropdown.Item active={!value} onClick={handlePick(onChange, name, null)}>
        <AnyLabel />
      </NavDropdown.Item>
      <Enum.DealStatus>
        {types =>
          Object.keys(types.d).map(type => (
            <NavDropdown.Item
              onClick={handlePick(onChange, name, type)}
              className="d-flex align-items-center"
              active={type === value}
              href={'#' + type}
              key={type}
            >
              <Badge
                variant={type === 'OPEN' ? 'success' : 'secondary'}
                className="circle circle-sm mr-2"
              />
              {types.d[type]}
            </NavDropdown.Item>
          ))
        }
      </Enum.DealStatus>
    </NavDropdown>
  );
}

// -------------------------------------------------------------------------------------------------

function handlePick(
  onChange: (*) => void,
  name: string,
  value: null | string
): (e: SyntheticEvent<*>) => void {
  return function(e: SyntheticEvent<*>) {
    e.preventDefault();
    onChange && onChange({ name, value });
  };
}

function AnyLabel(): React.Node {
  return <I18n id="any" d="Any" />;
}
