// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import type { ForgottenActionVariables } from '../../_helpers/Api/Auth';

import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import Form, { TextInput } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

const forgottenValidation = {
  email: { required: true, email: true }
};

export default function ForgottenPage(props: *): React.Node {
  return (
    <Layout.Auth>
      <h1 className="mb-4">
        <I18n id="title" d="Forgot password?" />
      </h1>
      <p className="mb-4">
        <I18n id="subtitle" d="Enter your email below" />
      </p>
      <AuthApi action="forgotten">
        {(action, { loading }) => (
          <Form
            onSubmit={handleSubmit(action)}
            validationRules={forgottenValidation}
            disabled={loading}
          >
            <BForm.Group>
              <I18n id="emailPlaceholder" d="Email">
                {placeholder => (
                  // $FlowFixMe
                  <Form.Field
                    placeholder={placeholder.value}
                    autoComplete="new-email"
                    component={TextInput}
                    type="email"
                    name="email"
                  />
                )}
              </I18n>
            </BForm.Group>
            <Row>
              <Col>
                <Button variant="primary" block type="submit" disabled={loading}>
                  <I18n id="submit" d="Reset" />
                </Button>
              </Col>
              <Col>
                <Link component={Button} to="/auth/login" variant="outline-dark" block>
                  <I18n id="send" d="Cancel" />
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </AuthApi>
    </Layout.Auth>
  );
}

// handle submit action
const handleSubmit = (action: ?(ForgottenActionVariables) => Promise<*>) => (
  data: ForgottenActionVariables
) => {
  action && action(data).then(handleResponse);
};

// display result message
const handleResponse = response => {
  let message = null;
  let err = false;
  if (response && response.ok) {
    message = <I18n id="successMessage" d="Well well well, check your mail!" />;
  } else {
    err = true;
    message = (
      <I18n
        id="errMessage$"
        d={{
          UNKNOWN_ERROR: 'Ooops! Something went wrong.',
          INVALID_EMAIL: 'Plese enter valid email'
        }}
        v={response.code === 'INVALID_EMAIL' ? 'INVALID_EMAIL' : 'UNKNOWN_ERROR'}
      />
    );
  }
  toastr({
    type: err ? 'error' : 'success',
    message
  });
};
