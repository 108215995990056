// @flow

import idx from 'idx';
import { RecordSource, Environment, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
  cacheMiddleware,
  perfMiddleware
} from 'react-relay-network-modern';

import Logger from '../Logger';

let AcceptLanguage = 'en';

const source = new RecordSource();
const store = new Store(source);

const cacheTTL = parseInt(process.env.REACT_APP_GQL_CACHE, 10) || 0; // minutes
const middlewares = [];

middlewares.push(
  urlMiddleware({
    url: getEndpoint(),
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'Accept-Language': AcceptLanguage || 'en'
    },
    credentials: 'include'
  })
);

middlewares.push(function localeMiddleware(next) {
  return req => {
    req.fetchOpts.headers['Accept-Language'] = AcceptLanguage;
    return next(req);
  };
});

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(function devMidleware(next) {
    return req => {
      const url = idx(req, _ => _.fetchOpts.url) || '';
      // $FlowFixMe
      const operation = idx(req, _ => _.operation.name) || null;

      req.fetchOpts.url =
        operation && typeof operation === 'string' ? `${url}?operation=${operation}` : url;

      return next(req);
    };
  });
}

// TODO: make it better
middlewares.push(function unauthorizedMiddleware(next) {
  return req => {
    return next(req)
      .then(res => {
        if (!res.ok && res.status === 401) {
          window.location.reload();
        } else if (!res.ok) {
          Logger.error(res);
        }
        return res;
      })
      .catch(e => {
        if (e && e.res && e.res.ok === false && e.res.status === 401) {
          window.location.reload(); // unauthorized -> login
        } else {
          Logger.error(e); // log all other errors
        }
        throw e;
      });
  };
});

if (cacheTTL) {
  middlewares.push(
    cacheMiddleware({
      size: 100,
      ttl: cacheTTL * 60 * 1000
    })
  );
}

if (process.env.REACT_APP_GQL_PERF) {
  middlewares.push(perfMiddleware());
}

// TODO: Batch midleware = add server support

const options = {};

const network = new RelayNetworkLayer(middlewares, options);

const environment = new Environment({
  store,
  network
});

export default environment;

export function setLocaleHeader(locale: string): void {
  AcceptLanguage = locale;
}

export function getEndpoint() {
  return (process.env.REACT_APP_API_HOST || '') + (process.env.REACT_APP_GQL_ENDPOINT || '');
}
