// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Redirect from 'react-router-dom/Redirect';

import MyContract from './MyContract';
import Logger from '@app/Logger';
import { Query } from '../../../_helpers/Api';
import Layout from '../../../_components/Layout';
import LoadingContent from '../../_components/LoadingContent';

// -------------------------------------------------------------------------------------------------

export default function MyContractsPage(props: *): React.Node {
  return (
    <Layout.Common narrow>
      <Query name="myContracts">
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            Logger.error(error);
            return <Redirect to="/error/500" />;
          }

          const contracts =
            data &&
            data.myContracts &&
            data.myContracts.edges &&
            data.myContracts.edges.map(it => it.node);

          if (!contracts) {
            return <Redirect to="/error/404" />;
          }

          return (
            <>
              <h1 className="mb-4 mt-4">
                <I18n id="title" d="My Replies" />
              </h1>

              <div className="my-contracts-list">
                {contracts.length > 0 ? (
                  contracts.map(contract => <MyContract contract={contract} key={contract.id} />)
                ) : (
                  <Row>
                    <Col className="d-flex flex-column text-primary p-3">
                      <I18n id="noResults" d="No replies were found." />
                    </Col>
                  </Row>
                )}
              </div>
            </>
          );
        }}
      </Query>
    </Layout.Common>
  );
}
