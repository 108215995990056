// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';

function Language(props: *): React.Node {
  return <I18n {...props} id="null$" d={{ CZ: 'CZ', SK: 'SK', EN: 'EN' }} />;
}

Language.types = ['CZ', 'SK', 'EN'];

export default Language;
