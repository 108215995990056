// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import Link from '../../../_components/Link';
import Icon from '../../../_components/Icon';
import Enum from '../../../_components/Enum';
import DateTime from '../../../_components/DateTime';

// -------------------------------------------------------------------------------------------------

const STATUS_COLOR_MAP = {
  PROPOSED: 'secondary',
  COMPLETED: 'success',
  CLOSED: 'secondary',
  FAILED: 'danger',
  OPEN: 'warning'
};

// -------------------------------------------------------------------------------------------------

export default function MyContract(props: { contract: * }): React.Node {
  const contract = props.contract;
  const deal = contract.deal;
  const company = contract.consumer;
  if (!deal || !contract || !company) {
    return null;
  }
  return (
    <div className="my-contract-item">
      {/* Summary - status */}
      <ContractItemHeader deal={deal} company={company} contract={contract} />

      {/* Details */}
      <ContractItemTable deal={deal} contract={contract} />

      {/* Action buttons */}
      <div className="my-contract-item__footer">
        <Link component={Button} to={`/deal/edit/${deal.id}`} className="mr-3" size="sm">
          <I18n id="editDealButton" d="Edit deal" />
        </Link>

        <Link
          to={`/account/my-contracts/update/${contract.id}`}
          component={Button}
          variant="dark"
          size="sm"
        >
          <I18n id="editContractButton" d="Edit Reply" />
        </Link>
      </div>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

// display contract item header
function ContractItemHeader(props: { deal: *, company: *, contract: * }): React.Node {
  const { deal, company, contract } = props;

  const summary = (
    <>
      <strong className="mr-1">{deal.summary}</strong>
      <I18n id="with" d=" with " />
      <Link to={`/company/${company.id}`} className="text-primary ml-1">
        {company.name}
      </Link>
    </>
  );

  return (
    <div className="my-contract-item__header">
      <div className="d-flex mb-2 mb-md-3">
        <Icon name={deal.cooperation} className="float-left mr-2 text-primary" size={24} />

        <div className="d-none d-md-block" style={{ flex: 1 }}>
          {summary}
        </div>

        <div>
          <Badge variant={STATUS_COLOR_MAP[contract.status] || 'secondary'} className="ml-auto">
            <Enum.ContractStatus v={contract.status} />
          </Badge>
        </div>
      </div>

      <div className="d-block d-md-none mb-3">{summary}</div>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

// display contract details
function ContractItemTable(props: { contract: *, deal: * }): React.Node {
  const { contract, deal } = props;

  return (
    <Row className="my-contract-item__table mb-2">
      <Col xs={6} md={3}>
        <h5 className="mb-2">
          <I18n id="started" d="Started" />
        </h5>
        {contract.created ? <DateTime.Date value={contract.created} /> : '-'}
      </Col>
      <Col xs={6} md={3}>
        <h5 className="mb-2">
          <I18n id="dealType" d="Opportunity type" />
        </h5>
        <Icon name={deal.type.toLowerCase()} className="mr-2" />
        <Enum.DealType v={deal.type} />
      </Col>
      <Col className="mt-3 mt-md-0 d-block my-contract-item__categories" xs={12} md={6}>
        <h5 className="mb-2">
          <I18n id="category" d="Category" />
        </h5>
        {deal.category && (
          <Badge variant="gray" className="mr-1 mb-1 float-left">
            {deal.category.name}
          </Badge>
        )}
      </Col>
    </Row>
  );
}
