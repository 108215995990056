// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router-dom/Redirect';

import Link from '_components/Link';
import toastr from '_helpers/toastr';
import { AuthApi } from '_helpers/Api';
import BBCode from '_components/BBCode';
import Layout from '_components/Layout';
import DateTime from '_components/DateTime';
import { Context as IdentityContext } from '_helpers/Identity';

export default function ProfilePage(): React.Node {
  const { identity, refreshIdentity } = React.useContext(IdentityContext);

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/" />;
    }
  }, []);

  const free = !identity.company.paying;
  const created = identity.company.created;
  const expirationDate =
    free || identity.company.subscription
      ? new Date(new Date(created).setMonth(new Date(created).getMonth() + 6))
      : null;
  const today = new Date().getTime();

  const subscriptionData = identity.company.subscription && {
    subscription: identity.company.subscription || null,
    nextCharge: new Date(identity.company.subscription.expires),
    unsubscribed: !!identity.company.subscription.unsubscribed
  };

  const getStatus = () => {
    if (
      (free && !subscriptionData) ||
      (free &&
        subscriptionData &&
        subscriptionData.nextCharge.getTime() < today &&
        expirationDate &&
        subscriptionData.nextCharge.getTime() < expirationDate.getTime())
    ) {
      return <I18n id="freeStatus" d="Free" />;
    }

    return <I18n id="premiumStatus" d="Premium" />;
  };

  const getSubscriptionData = () => {
    if (
      (free && !subscriptionData) ||
      (free &&
        subscriptionData &&
        subscriptionData.nextCharge.getTime() < today &&
        expirationDate &&
        subscriptionData.nextCharge.getTime() < expirationDate.getTime())
    ) {
      return (
        <>
          <Col xs={12} sm={6} className="mb-2">
            <I18n id="freeAccountValidity" d="Valid until" />
          </Col>
          <Col xs={12} sm={6} className="mb-2">
            {expirationDate ? (
              <DateTime.Date value={expirationDate} />
            ) : (
              <I18n id="nextChargeUnknown" d="Unknown" />
            )}
          </Col>
          {expirationDate && today > expirationDate.getTime() && (
            <Col xs={12} sm={12} className="mt-2 text-center">
              <Badge variant="danger">
                <I18n id="expiredAccount" d="Validity of your account has expired" />
              </Badge>
            </Col>
          )}
        </>
      );
    }

    if (
      !free ||
      (identity.company.subscription && expirationDate && today > expirationDate.getTime())
    ) {
      return (
        <>
          <Col xs={12} sm={6} className="mb-2">
            <I18n id="typeTitle" d="Subscription type" />
          </Col>
          <Col xs={12} sm={6} className="mb-2">
            <I18n
              d="{type, select, ANNUAL{Yearly} MONTHLY{Monthly} other{Unknown}}"
              type={identity.company.subscription.type}
              id="typeValue"
            />
          </Col>

          <Col xs={12} sm={6} className="mb-2">
            {subscriptionData && today > subscriptionData.nextCharge.getTime() ? (
              <I18n id="freeAccountValidity" d="Valid until" />
            ) : (
              <I18n id="paymentNextCharge" d="Next charge" />
            )}
          </Col>
          <Col xs={12} sm={6} className="mb-2">
            {subscriptionData.nextCharge ? (
              <DateTime.Date value={subscriptionData.nextCharge} />
            ) : (
              <I18n id="nextChargeUnknown" d="Unknown" />
            )}
          </Col>

          {identity.company.subscription.type !== 'FREE' && (
            <>
              <Col xs={12} sm={6} className="mb-2">
                <I18n id="paymentTitle" d="Payment method" />
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <I18n id="paymentValue" d="Credit card" />
              </Col>
            </>
          )}
          {subscriptionData && today > subscriptionData.nextCharge.getTime() && (
            <>
              <Col xs={12} sm={12} className="mt-2 text-center">
                <Badge variant="danger">
                  <I18n id="expiredAccount" d="Validity of your account has expired" />
                </Badge>
              </Col>
            </>
          )}

          {!free && identity.company.subscription.type !== 'FREE' && (
            <Col xs={12} className="mt-4">
              {subscriptionData.unsubscribed ? (
                <p className="text-left text-md-center">
                  <I18n
                    id="unsubscribedText"
                    d='You have already requested end of subscription plan, please wait until we process your request. Feel free to [link href="/contact"]contact Us[/link]'
                  >
                    {({ value }) => <BBCode>{value}</BBCode>}
                  </I18n>
                </p>
              ) : (
                <AuthApi action="unsubscribePayment">
                  {(action, { loading, retry }) => (
                    <div>
                      <Button
                        className="full-width"
                        onClick={getUnsubscribeHandler(action, retry)}
                        disabled={loading}
                        variant="danger"
                        to="/subscribe"
                      >
                        <I18n id="unSubscribeButton" d="Unsubscribe" />
                      </Button>
                    </div>
                  )}
                </AuthApi>
              )}
            </Col>
          )}
        </>
      );
    }

    return <></>;
  };

  return (
    <Layout.Auth>
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12}>
          <Card className="p-5" style={{ minWidth: '100%' }}>
            <h2>
              <I18n id="title" d="My account" />
            </h2>

            <Row className="mt-4 mb-4 text-left">
              <Col xs={12} sm={6} className="mb-2">
                <I18n id="statusTitle" d="Account status" />
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <Badge variant={free ? 'danger' : 'success'}>{getStatus()}</Badge>
              </Col>
              {getSubscriptionData()}
            </Row>

            {free && (
              <div className="mb-4">
                <p className="text-left text-md-center mb-4">
                  <I18n
                    id="freeText"
                    d="Become a premium user[br /] to utilize all features on myB3B.com"
                  >
                    {({ value }) => <BBCode>{value}</BBCode>}
                  </I18n>
                </p>
                <Link className="full-width" component={Button} to="/subscribe">
                  <I18n id="subscribeButton" d="Subscribe now" />
                </Link>
              </div>
            )}

            <Link
              className="full-width"
              component={Button}
              to="account/reset"
              variant="outline-dark"
            >
              <I18n id="loginDetailsReset" d="Reset login details" />
            </Link>
          </Card>
        </Col>
      </Row>
    </Layout.Auth>
  );
}

function getUnsubscribeHandler(
  apiAction: null | (() => Promise<*>),
  refetch: ?() => void
): () => void {
  return function handleUnsubscribe(): void {
    apiAction &&
      apiAction().then(res => {
        toastr({
          type: res.ok ? 'success' : 'error',
          message: res.ok ? (
            <I18n
              id="unsubscribeSucceeeded"
              d="Done, your request is recorded, please wait for confirmation message"
            />
          ) : (
            <I18n
              id="unsubscribeFailed"
              d='Ooops, request failed, please try again or contact us [link href="/contact"]directly[/link]'
            >
              {({ value }) => <BBCode>{value}</BBCode>}
            </I18n>
          )
        });
        res.ok && refetch && refetch();
      });
  };
}
