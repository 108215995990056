// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import type { OnChangeEvent } from '../../';

import Icon from '../../../Icon';
import BBCode from '../../../BBCode';
import MapPlacePicker from '../MapPlacePicker';
import GoogleAutocompleteInput, { placeToString } from '../../../GoogleAutocompleteInput';
import Form, { TextInput, CountryInput, USStateInput, FormConsumer } from '../..';

// -------------------------------------------------------------------------------------------------

export type LocationModalProps = {
  onRequestConfirm: (null | Place) => void,
  onRequestClose: () => void,
  defaultValue: null | Place,
  title?: React.Node,
  open: boolean
};

export type LocationGroupProps = {
  batchChange: () => void,
  value: null | Place,
  name: string,
  focus: boolean,
  modal: boolean,
  setModal: any,
  submit: any
};

// -------------------------------------------------------------------------------------------------

export default function LocationModal(props: LocationModalProps): React.Node {
  const { defaultValue, open, onRequestClose, title, onRequestConfirm } = props;
  const [mapSearch, setMapSearch] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const placeValidationRules = {
    street: { required: true },
    city: { required: true },
    countryCode: { required: true },
    stateCode: { required: (_, d) => d.countryCode === 'US' },
    postalCode: { required: true, zip: true }
  };

  const onBlur = React.useCallback(() => setMapSearch(true), []);

  const onFocus = React.useCallback(() => setMapSearch(false), []);

  const onLocationModalSubmit = React.useCallback(() => setModalOpen(true), []);

  return (
    <Modal show={open} onHide={onRequestClose} size="lg">
      <Form
        validationRules={placeValidationRules}
        onSubmit={onLocationModalSubmit}
        onBlur={onBlur}
        onFocus={onFocus}
        defaultValue={{
          ...(defaultValue || {}),
          hash: placeToString(defaultValue)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Icon name="location" className="mr-3" />
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* Location picker - autocomplete / map */}
            <Col lg={6}>
              <Form.Field
                component={LocationGroup}
                name=""
                focus={mapSearch}
                modal={modalOpen}
                setModal={setModalOpen}
                submit={onRequestConfirm}
              />
            </Col>

            {/* Address details */}
            <Col lg={6}>
              <BForm.Group controlId="locationInput.street">
                <BForm.Label>
                  <I18n id="streetLabel" d="Street" />
                  <span className="form-label-required"> *</span>
                </BForm.Label>

                <Form.Field component={TextInput} name="street" />
              </BForm.Group>

              <BForm.Group controlId="locationInput.city">
                <BForm.Label>
                  <I18n id="cityLabel" d="City" />
                  <span className="form-label-required"> *</span>
                </BForm.Label>

                <Form.Field component={TextInput} name="city" />
              </BForm.Group>

              <BForm.Group controlId="locationInput.postalCode">
                <BForm.Label>
                  <I18n id="postalCodeLabel" d="Zip" />
                  <span className="form-label-required"> *</span>
                </BForm.Label>

                <Form.Field component={TextInput} name="postalCode" />
              </BForm.Group>

              <BForm.Group controlId="locationInput.countryCode">
                <BForm.Label>
                  <I18n id="countryLabel" d="Country" />
                  <span className="form-label-required"> *</span>
                </BForm.Label>

                <Form.Field
                  id="locationInput.countryCode"
                  component={CountryInput}
                  name="countryCode"
                />
                <div className="mt-2 text-small">
                  <I18n d="Select a country from the list." id="countryDescription" />
                </div>
              </BForm.Group>

              <FormConsumer>
                {({ data }) =>
                  data.countryCode === 'US' && (
                    <BForm.Group controlId="locationInput.stateCode">
                      <BForm.Label>
                        <I18n id="stateLabel" d="State" />
                      </BForm.Label>

                      <Form.Field
                        component={USStateInput}
                        id="locationInput.state"
                        name="stateCode"
                      />
                    </BForm.Group>
                  )
                }
              </FormConsumer>
            </Col>
          </Row>
        </Modal.Body>

        {/* modal footer - buttons */}
        <FormConsumer>
          {({ isChanged }) => (
            <Modal.Footer>
              <Button variant="outline-dark" onClick={onRequestClose}>
                <I18n id="cancelButton" d="Cancel" />
              </Button>
              <Button variant="success" type="submit" disabled={!isChanged}>
                <I18n id="confirmButton" d="Apply" />
                <Icon name="check" className="ml-3 sole-icon" />
              </Button>
            </Modal.Footer>
          )}
        </FormConsumer>
      </Form>
    </Modal>
  );
}

// -------------------------------------------------------------------------------------------------

// component is re-rendered only when coords property is changed
// for more info check  <Form.Field name="coords"...
function LocationGroup(props: LocationGroupProps): React.Node {
  const { batchChange, value, name, focus, modal, setModal, submit } = props;
  const [val, setVal] = React.useState(value);
  const myRef = React.createRef();

  React.useEffect(() => {
    if (JSON.stringify(val) !== JSON.stringify(value) && !modal) {
      if (focus && value && value.countryCode && value.postalCode && value.street && value.city) {
        myRef.current.handleAddressInputsChange();
      }
      setVal(value);
    }
  }, [focus]);

  const toggleOpen = React.useCallback(
    _ => {
      setModal(modal => !modal);
    },
    [setModal]
  );

  function submitHandler(): void {
    toggleOpen();
    submit && submit(value);
  }

  return (
    <>
      <BForm.Group controlId="locationFieldset.location">
        <BForm.Label>
          <I18n id="locationLabel" d="Search location" />
        </BForm.Label>
        <I18n id="phonePlaceholder" d="Enter location">
          {placeholder => (
            <GoogleAutocompleteInput
              onChange={changePlace(batchChange)}
              placeholder={placeholder.value}
              value={value}
              name={name}
            />
          )}
        </I18n>
      </BForm.Group>

      <BForm.Group controlId="locationFieldset.map">
        <MapPlacePicker onChange={changePlace(batchChange)} value={value} name={name} ref={myRef} />
      </BForm.Group>

      <Modal className="confirmationModal" show={modal} onHide={toggleOpen} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Icon name="location" className="mr-3" />
            <I18n id="confirmationModalTitle" d="Confirm entered Location" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col lg={12} className="mb-16">
            <I18n
              id="confirmationModalLabel"
              d="The entered address will be used for precise recommendation of opportunities.[br /]Please confirm it."
            >
              {({ value }) => <BBCode>{value}</BBCode>}
            </I18n>
          </Col>
          <Col lg={12} className="mb-16">
            <MapPlacePicker
              onChange={changePlace(batchChange)}
              value={value}
              height={'460px'}
              name={name}
              zoom={16}
              confirmation={'confirm'}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={toggleOpen}>
            <I18n id="cancelButton" d="Cancel" />
          </Button>
          <Button variant="success" onClick={submitHandler}>
            <I18n id="confirmationModalButton" d="Confirm" />
            <Icon name="check" className="ml-3 sole-icon" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// -------------------------------------------------------------------------------------------------

function changePlace(batcher: (*) => void): (OnChangeEvent<Place>) => void {
  return function onChangePlace(event: OnChangeEvent<Place>) {
    let n = {};
    if (event.value) {
      n = {
        ...(event.value.coords && !event.value.coords.init ? event.value : {}),
        coords: event.value.coords ? { ...event.value.coords } : { lng: 0, lat: 0 },
        hash: placeToString(event.value)
      };
    }
    batcher(n);
  };
}
