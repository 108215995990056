// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';

import ErrorLayout from './ErrorLayout';
import BBCode from '../../_components/BBCode';

// -------------------------------------------------------------------------------------------------

export default function Error404Page(): React.Node {
  return (
    <ErrorLayout>
      <h1>
        <I18n id="title" d="404 [br /]Page not found">
          {({ value }) => <BBCode>{value}</BBCode>}
        </I18n>
      </h1>
      <p>
        <I18n
          d="The page you are looking for is not here.[br /]Link might be broken or wrong"
          id="text"
        >
          {({ value }) => <BBCode>{value}</BBCode>}
        </I18n>
      </p>
    </ErrorLayout>
  );
}
