// @flow

import I18n from '_helpers/I18n';
import idx from 'idx';
import * as React from 'react';
import { graphql } from 'react-relay';
import { useState } from 'react';
import Logger from '@app/Logger';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router/Redirect';

import Link from '../../_components/Link';
import Enum from '../../_components/Enum';
import { Query, commitMutationPromise } from '../../_helpers/Api';
import BBCode from '../../_components/BBCode';
import Layout from '../../_components/Layout';
import DateTime from '../../_components/DateTime';
import LoadingContent from '../_components/LoadingContent';
import { Context as IdentityContext } from '../../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export type DealDetailPageProps = RouteProps<{ dealId: string }>;

// -------------------------------------------------------------------------------------------------

export default function DealDetailPage(props: DealDetailPageProps): React.Node {
  const { identity, refreshIdentity, loading } = React.useContext(IdentityContext);
  const dealId = idx(props, _ => _.match.params.dealId);
  const { match } = props;
  const [mobile, setMobile] = window.innerWidth > 1200 ? useState(false) : useState(true);

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/" />;
    }
  }, []);

  React.useEffect(() => {
    if (identity && identity.company.paying === false) {
      const timer = setTimeout(() => {
        dealId && proposeContract(dealId);
      }, 3000);

      return () => {
        timer && clearTimeout(timer);
      };
    }
  });

  const mobileSize = () => {
    if (window.innerWidth < 1200) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener('resize', mobileSize);

  if (loading) {
    return <LoadingContent />;
  }

  return (
    <Layout.Common narrow>
      <Query name="dealDetail" variables={{ dealId: match.params.dealId }}>
        {({ loading, error, data }) => {
          const deal = data && data.deal;

          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            return <Redirect to="/error/500" />;
          }

          if (!deal) {
            return <Redirect to="/error/404" />;
          }

          if (identity && identity.company.paying === false) {
            localStorage.setItem(
              'contactURL',
              `/deal/detail/${deal.id}/contact/${deal.company.id}`
            );
          }

          if (identity && identity.company.paying === true && localStorage.getItem('contactURL')) {
            localStorage.removeItem('contactURL');
          }

          return (
            <Row>
              <Col md={12} lg={9}>
                <Row className="mb-5">
                  <Col>
                    <h1>
                      <I18n id="title" d="Opportunity details">
                        {({ value }) => <BBCode>{value}</BBCode>}
                      </I18n>
                      <br />
                      <span className="text-primary text-thin mt-2 d-block">{deal.summary}</span>
                    </h1>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="type" d="Opportunity type" />
                    </b>
                  </Col>
                  <Col>
                    <Enum.DealType v={deal.type} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="cooperation" d="Partnership type" />
                    </b>
                  </Col>
                  <Col>
                    <Enum.Cooperation v={deal.cooperation} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="category" d="Category" />
                    </b>
                  </Col>
                  <Col>{deal.category && <span>{deal.category.name}</span>}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="expires" d="Expires" />
                    </b>
                  </Col>
                  <Col>{deal.expires ? <DateTime.Date value={deal.expires} /> : '-'}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="description" d="Opportunity description" />
                    </b>
                  </Col>
                  <Col className="text-justify">{deal.description}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <b>
                      <I18n id="keywords" d="Keywords" />
                    </b>
                  </Col>
                  <Col>
                    {deal.keywords.reduce(
                      (result: string, keyword: string, key: number, array: Array<string>) =>
                        `${result}${keyword ? '#' : ''}${keyword}${
                          array.length > key + 1 ? ' ' : ''
                        }`,
                      ''
                    )}
                  </Col>
                </Row>
                {deal.partnerRequirements && (
                  <Row className="mb-3">
                    <Col md={4}>
                      <b>
                        <I18n id="requirements" d="Partner requirements" />
                      </b>
                    </Col>
                    <Col>{deal.partnerRequirements}</Col>
                  </Row>
                )}
                {identity && identity.company.paying ? (
                  <>
                    <Row className="mb-3">
                      <Col md={4}>
                        <b>
                          <I18n id="attachments" d="Attachments" />
                        </b>
                      </Col>
                      <Col>
                        {deal.attachments.map((attachment, k, a) => (
                          <div key={k + '-' + attachment.id}>
                            <a rel="noopener noreferrer" href={attachment.url} target="_blank">
                              {attachment.name}
                            </a>
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <b>
                          <I18n id="video" d="Video" />
                        </b>
                      </Col>
                      <Col>
                        <a href={deal.video} rel="noopener noreferrer" target="_blank">
                          {deal.video}
                        </a>
                      </Col>
                    </Row>
                  </>
                ) : null}
                <Row className="mb-2">
                  <Row className="col-12 mt-5 align-items-center no-print">
                    <Col className="col-12 col-xl-6 col-sm-10">
                      {identity && identity.company.paying ? (
                        <React.Fragment>
                          <Link
                            to={`/deal/detail/${deal.id}/contact/${deal.company.id}`}
                            component={Button}
                            variant="primary"
                            className="mr-3 min-w-275"
                          >
                            <Row className="align-items-center">
                              <Col className="col-12 text-center p-0">
                                <I18n id="contactCompany" d="Contact company" />
                              </Col>
                            </Row>
                          </Link>
                        </React.Fragment>
                      ) : (
                        <Link
                          to="/subscribe?redirectToURL=1"
                          component={Button}
                          variant="primary"
                          className="mr-3"
                        >
                          <I18n id="subscribeButton" d="Subscribe" />
                        </Link>
                      )}
                    </Col>
                    {mobile ? (
                      <Row className="mt-2 mb-2 text-small no-print">
                        <Col xs={10} sm={10} md={10}>
                          <Col className="text-justify">
                            <I18n
                              id="contactCompanyDescription"
                              d="Company will get information that you are interested in the opportunity."
                            />
                          </Col>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                    <Col className="col-6 col-xs-3 col-sm-6 col-xl-3">
                      <Link
                        className="mt-3 mt-sm-0 mt-xs-0 deal-detail-button"
                        component={Button}
                        back
                        variant="outline-dark"
                      >
                        <I18n id="backButton" d="Back" />
                      </Link>
                    </Col>
                    <Col className="col-6 col-xs-3 col-sm-6 col-xl-3">
                      <Button
                        className="mt-3 mt-sm-0 deal-detail-button"
                        onClick={window.print}
                        variant="outline-dark"
                      >
                        <I18n id="printButton" d="Print" />
                      </Button>
                    </Col>
                  </Row>
                </Row>
                {mobile ? (
                  ''
                ) : (
                  <Row className="mb-3 text-small no-print">
                    <Col sm={6} md={6}>
                      <I18n
                        id="contactCompanyDescription"
                        d="Company will get information that you are interested in the opportunity."
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          );
        }}
      </Query>
    </Layout.Common>
  );
}

function proposeContract(dealId: string): void {
  commitMutationPromise({
    mutation: proposeContractMutation,
    variables: { dealId }
  })
    .then((response: { contract: { deal: { summary: string } } }) => ({
      response,
      error: undefined
    }))
    .catch(error => ({ error, response: undefined }))
    .then(({ response, error }) => {
      if (!response || error) {
        Logger.error(`Reply proposition failed for Opportunity '${dealId}'`);
      } else {
        Logger.error(`New Reply Proposed: '${response.contract.deal.summary}'`);
      }
    });
}

const proposeContractMutation = graphql`
  mutation DealDetailPageProposeContractMutation($dealId: ID!) {
    contract: proposeContract(id: $dealId) {
      id
      deal {
        id
        summary
      }
    }
  }
`;
