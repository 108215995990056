// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import type { OnChangeEvent, ValidationRules } from '../../../_components/Form';

import Enum from '../../../_components/Enum';
import Messaging from '../../../_components/Form/_components/Messaging';

import Form, { FormConsumer, ToggleInput, TextInput } from '../../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type UserFormData = {
  messaging: null | { [number]: MessagingInput },
  salutation: null | Salutation,
  contactEmail: null | string,
  firstName: null | string,
  lastName: null | string,
  password: null | string,
  linkedin: null | string,
  phone: null | string,
  email: null | string,
  lang: null | string
};

export type UserFormProps = {
  onSubmit: (null | UserFormData) => void,
  defaultValue?: null | UserFormData,
  disabled?: boolean
};

// -------------------------------------------------------------------------------------------------

const UserFormValidationRules: ValidationRules<UserFormData> = {
  firstName: { required: true, minLength: 3 },
  lastName: { required: true, minLength: 3 },
  password: { minLength: 8, hasDigit: true },
  phone: { required: true, phone: true },
  salutation: { required: true },
  linkedin: { linkedIn: true },
  messaging: { maxCount: 6 },
  contactEmail: { email: true },
  lang: { required: true }
};

const defaultUserFormValue = {
  contactEmail: null,
  salutation: null,
  firstName: null,
  lastName: null,
  password: null,
  linkedin: null,
  messaging: null,
  phone: null,
  email: null,
  lang: null
};

// -------------------------------------------------------------------------------------------------

export default function UserForm(props: UserFormProps): React.Node {
  const { onSubmit, disabled, defaultValue } = props;

  if (defaultValue.contactEmail === null) {
    defaultValue.contactEmail = defaultValue.email;
  }

  return (
    <Form
      validationRules={UserFormValidationRules}
      defaultValue={{ ...defaultUserFormValue, ...(defaultValue || {}) }}
      onChangeDecorator={decorateUserFormChange}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      <Row>
        <Col sm={12} md={6}>
          <BForm.Group>
            <BForm.Label>
              <I18n id="salutationLabel" d="Salutation" />
              <span className="form-label-required"> *</span>
            </BForm.Label>

            <Enum.Salutation>
              {({ d }) => (
                // $FlowFixMe
                <Form.Field
                  options={Object.keys(d).map(key => ({ value: key, label: d[key] }))}
                  component={ToggleInput}
                  name="salutation"
                />
              )}
            </Enum.Salutation>
          </BForm.Group>
        </Col>
        <Col sm={12} md={6}>
          <BForm.Group>
            <BForm.Label>
              <I18n id="localeToggleLabel" d="Language" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <Enum.Language>
              {({ d }) => (
                // $FlowFixMe
                <Form.Field
                  options={Object.keys(d).map(key => ({ value: key, label: d[key] }))}
                  component={ToggleInput}
                  name="lang"
                />
              )}
            </Enum.Language>
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <BForm.Group controlId="accountForm.name">
            <BForm.Label>
              <I18n id="nameLabel" d="Name" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            {/* $FlowFixMe */}
            <Form.Field component={TextInput} name="firstName" />
          </BForm.Group>
        </Col>
        <Col sm={12} md={6}>
          <BForm.Group controlId="accountForm.surname">
            <BForm.Label>
              <I18n id="surnameLabel" d="Surname" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <Form.Field component={TextInput} name="lastName" />
          </BForm.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <BForm.Group className="mb-2" controlId="accountForm.contactEmail">
            <BForm.Label>
              <I18n id="contactEmailLabel" d="Contact email address" />
            </BForm.Label>
            <I18n id="contactEmailPlaceholder" d="john@smith.com">
              {placeholder => (
                // $FlowFixMe
                <Form.Field
                  placeholder={placeholder.value}
                  component={TextInput}
                  name="contactEmail"
                  type="email"
                />
              )}
            </I18n>
            <div className="mt-2 text-small">
              <I18n
                id="contactEmailDescription"
                d="This e-mail will be visible only to premium users of the platform"
              />
            </div>
          </BForm.Group>
        </Col>
        <Col sm={12} md={6}>
          <BForm.Group className="mb-2" controlId="accountForm.phone">
            <BForm.Label>
              <I18n id="phoneLabel" d="Phone number" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <I18n id="phonePlaceholder" d="+421xxxxxxxxx">
              {placeholder => (
                <Form.Field
                  component={TextInput}
                  placeholder={placeholder.value}
                  name="phone"
                  type="tel"
                />
              )}
            </I18n>
            <div className="mt-2 text-small">
              <I18n
                d="This phone number will be visible only to premium users of the platform"
                id="contactPhoneDescription"
              />
            </div>
          </BForm.Group>
        </Col>
      </Row>

      <BForm.Group controlId="accountForm.messaging">
        <BForm.Label>
          <I18n id="messagingLabel" d="Messaging Services" />
        </BForm.Label>
        {/* $FlowFixMe */}
        <Form.Field component={Messaging} max={6} name="messaging" />
      </BForm.Group>

      <BForm.Group controlId="accountForm.linkedin">
        <BForm.Label>
          <I18n id="linkedinLabel" d="LinkedIn Profile" />
        </BForm.Label>
        <I18n id="placeholder" d="Enter your LinkedIn Profile">
          {placeholder => (
            // $FlowFixMe
            <Form.Field
              placeholder={placeholder.value}
              component={TextInput}
              name="linkedin"
              description={
                <I18n id="linkedinDescription" d="Personal or corporate LinkedIn profile" />
              }
            />
          )}
        </I18n>
      </BForm.Group>

      <BForm.Group>
        <FormConsumer>
          {({ isChanged, reset }) => (
            <>
              <Button disabled={disabled || !isChanged} type="submit">
                <I18n id="submitButton" d="Save" />
              </Button>

              <Button
                disabled={disabled || !isChanged}
                variant="outline-dark"
                className="ml-0 ml-sm-3 mt-3 mt-sm-0 d-block d-sm-inline-block"
                onClick={reset}
              >
                <I18n id="cancelButton" d="Cancel" />
              </Button>
            </>
          )}
        </FormConsumer>
      </BForm.Group>
    </Form>
  );
}

function decorateUserFormChange(e: OnChangeEvent<*>, data: any): any {
  if (e.name === 'linkedin' && e.value && e.value[0] === 'H') {
    let val = e.value;
    val = 'h' + val.substr(1);
    return { ...data, linkedin: val };
  }
  return data;
}
