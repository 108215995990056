// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';

const CooperationTitle = {
  SPARE_CAPACITY: function SpaceCapacityDescription(): React.Node {
    return <I18n id="spareTitle" d="Spare Production Capacity:" />;
  },
  SHARE_ASSETS: function ShareAssetsDescription(): React.Node {
    return <I18n id="assetsTitle" d="Use of Shared Assets:" />;
  },
  SHARE_PROCUREMENT: function ShareAssetsDescription(): React.Node {
    return <I18n id="procurementTitle" d="Shared procurement:" />;
  },
  RND_COOPERATION: function ShareAssetsDescription(): React.Node {
    return <I18n id="rndTitle" d="Research and Development" />;
  }
};

export default CooperationTitle;
