// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import BForm from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import confirmAction from './confirmAction';
import toastr from '../../../../_helpers/toastr';
import BBCode from '../../../../_components/BBCode';

// -------------------------------------------------------------------------------------------------

export type CreateContractModalTarget = {
  deal: { id: ID, summary: string, status: DealStatus },
  consumer: { name: string },
  id: ID
};

export type CreateContractModalContext = {|
  setTarget: (?CreateContractModalTarget) => void,
  target: ?CreateContractModalTarget
|};

const Context = React.createContext<CreateContractModalContext>({
  setTarget: function() {},
  target: null
});

// -------------------------------------------------------------------------------------------------

function CreateContractModal(props: { children: React.Node }): React.Node {
  const [target, setTarget] = React.useState<?CreateContractModalTarget>(null);
  const [dealStatus, setDealStatus] = React.useState<DealStatus>(null);

  return (
    <Context.Provider value={{ target, setTarget }}>
      <>
        {props.children}

        <Modal show={!!target} onHide={close(setTarget)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <I18n id="title" d="New Reply" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              {target && (
                <I18n
                  d="You are creating a new Reply with [b]{companyName}[/b] for your Opportunity [b]{dealSummary}[/b]"
                  companyName={target.consumer.name}
                  dealSummary={target.deal.summary}
                  id="text"
                >
                  {({ value }) => <BBCode>{value}</BBCode>}
                </I18n>
              )}
            </div>
            <div className="p-2">
              <I18n id="optionsTitle" d="Opportunity options:" />
              <BForm.Check id="closeDealOption" type="radio">
                <BForm.Check.Input
                  onChange={handleChange(setDealStatus, 'CLOSED')}
                  checked={dealStatus === 'CLOSED'}
                  name="status"
                  type="radio"
                />
                <BForm.Check.Label>
                  <I18n id="optionClose" d="Close Opportunity when Reply is created" />
                </BForm.Check.Label>
                <BForm.Control.Feedback type="valid">Close</BForm.Control.Feedback>
              </BForm.Check>

              <BForm.Check id="keepDealOption" type="radio">
                <BForm.Check.Input
                  onChange={handleChange(setDealStatus, 'OPEN')}
                  checked={dealStatus === 'OPEN'}
                  name="status"
                  type="radio"
                />
                <BForm.Check.Label>
                  <I18n id="optionKeep" d="Keep Opportunity open I want more partners" />
                </BForm.Check.Label>
                <BForm.Control.Feedback type="valid">Close</BForm.Control.Feedback>
              </BForm.Check>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={close(setTarget)}>
              <I18n id="cancelButton" d="Cancel" />
            </Button>

            <Button variant="success" onClick={confirm(setTarget, target, dealStatus)}>
              <I18n id="confirmButton" d="Create" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Context.Provider>
  );
}

CreateContractModal.Consumer = Context.Consumer;

export default CreateContractModal;

// -------------------------------------------------------------------------------------------------

function handleChange(setter: DealStatus => void, val: DealStatus): () => void {
  return function() {
    setter && setter(val);
  };
}

function close(setTarget: (?CreateContractModalTarget) => void): () => void {
  return function closeModal(): void {
    setTarget(null);
  };
}

function confirm(
  setTarget: (?CreateContractModalTarget) => void,
  target: ?CreateContractModalTarget,
  dealStatus: DealStatus
): () => void {
  return function confirmModal() {
    confirmAction(target, dealStatus)
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setTarget(null);
        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="contractCreationError" d="Ooops! Reply was not updated. Try again later" />
          ) : (
            <I18n id="contractCreated" d="Done! Reply is now opened" />
          )
        });
      });
  };
}

export { Context as ModalContext };
