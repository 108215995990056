// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';

import toastr from '../../_helpers/toastr';
import AuthApi from '../../_helpers/Api/Auth';
import Layout from '../../_components/Layout';
import LoadingContent from '../_components/LoadingContent';
import { Context as IdentityContext } from '../../_helpers/Identity';
import { routerHistory } from '../../_helpers/router';

// -------------------------------------------------------------------------------------------------

export default function LogoutPage(props: *): React.Node {
  const { updateIdentity } = React.useContext(IdentityContext);

  return (
    <AuthApi action="logout" autorun onFinish={handleLogout(updateIdentity)}>
      <Layout.Auth>
        <LoadingContent />
      </Layout.Auth>
    </AuthApi>
  );
}

// -------------------------------------------------------------------------------------------------

const handleLogout = (updateIdentity: (*) => void) => ({ data }) => {
  if (data) {
    toastr({ type: 'success', message: <I18n id="logoutDone" d="Successfully logged out" /> });
    updateIdentity(null);
    setTimeout(() => {
      routerHistory.replace('/');
    });
  } else {
    toastr({ type: 'error', message: <I18n id="logoutFailed" d="Ooops! Logout failed!" /> });
  }
};
