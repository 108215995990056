// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { manageContractQueryResponse } from './__generated__/manageContractQuery.graphql';

import env from '../relayEnvironment';

export type dealDetailProps = QueryRendererProps<manageContractQueryResponse>;

export default function manageContract(props: dealDetailProps): React.Node {
  return (
    <QueryRenderer
      variables={props.variables}
      render={props.children}
      environment={env}
      query={graphql`
        query manageContractQuery($contractId: ID!) {
          contract(id: $contractId) {
            id
            status
            notes
            created
            consumer {
              id
              name
            }
            deal {
              id
              summary
              type
              cooperation
              category {
                id
                name
              }
            }
          }
        }
      `}
    />
  );
}
