// @flow

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Collapse from 'react-bootstrap/Collapse';

import Form, { FormContext } from '../../../_components/Form';
import CooperationPicker from '../../_components/CooperationPicker';
import DealStatusDropdown from '../../_components/DealStatusDropdown';
import CategoryKeywordAutocomplete from '../../_components/CategoryKeywordAutocomplete';

export default function MyDealsCollapsibleFilter(): React.Node {
  const { data } = React.useContext(FormContext);
  return (
    <Collapse in={data.open}>
      <div className="pb-5">
        <div className="filter-panel">
          <Navbar className="inline" variant="light" bg="light">
            <Form.Field component={DealStatusDropdown} name="status" />

            <Form.Field component={CooperationPicker} name="cooperation" id="cooperationPicker" />
          </Navbar>
          <Navbar className="inline category-keyword" variant="light" bg="light">
            {/* $FlowFixMe */}
            <Form.Field
              style={{ paddingTop: 7, paddingBottom: 8 }}
              component={CategoryKeywordAutocomplete}
              id="filter-panel.categoryOrKeyword"
              name="categoryOrKeyword"
              block
            />
          </Navbar>
        </div>
      </div>
    </Collapse>
  );
}
