// @flow

import Logger from '_helpers/Logger';

const elemId = 'googleReCaptcha';

const TIMEOUT = 5000;
const KEY = process.env.REACT_APP_RECAPTCHA || '';

export default function getCaptchaToken(id: string): Promise<string> {
  const existingScript = document.getElementById(elemId);
  let resolved = false;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!resolved) {
        resolved = true;
        reject('Timeout error');
      }
    }, TIMEOUT);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=' + KEY;

      script.id = elemId;
      document.body && document.body.appendChild(script);

      script.onload = () => {
        execute(id)
          .then(resolve)
          .catch(reject);
      };

      script.onerror = error => {
        reject(error);
      };
    } else {
      execute(id)
        .then(resolve)
        .catch(reject);
    }
  });
}

function execute(id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const grecaptcha = window.grecaptcha;
    if (grecaptcha) {
      grecaptcha.ready(function() {
        grecaptcha.execute(KEY, { action: id }).then(function(token) {
          if (!token) {
            Logger.error('Loading captcha error failed');
            reject('Cannot get token');
          } else {
            resolve(token);
          }
        });
      });
    } else {
      Logger.error('Cannot load captch lib');
      reject('Cannot get token');
    }
  });
}
