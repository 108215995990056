// @flow

import qs from 'qs';

import type { OnChangeEvent } from '../../_components/Form';
import type { DealsFilterType } from '../_components/SearchResults';

import Enum from '../../_components/Enum';
import { defaultFilter } from '../SearchDealsPage';
import { routerHistory, getQueryParams } from '../../_helpers/router';

// -------------------------------------------------------------------------------------------------

/**
 * serialize deal filter into query params string
 *
 * @param {string} query
 */
export function serializeFilter(data: DealsFilterType) {
  const variables: { [string]: string } = {};
  const keywords =
    data.categoryOrKeyword && data.categoryOrKeyword.type === 'KEYWORD'
      ? [data.categoryOrKeyword.label]
      : null;
  if (data.type) {
    variables.type = data.type;
  }
  if (data.location.open && !data.location.ignore) {
    variables.open = '1';
  }
  if (data.cooperation && data.cooperation.length > 0) {
    variables.cooperation = data.cooperation.join(',');
  }
  if (keywords && keywords.length > 0) {
    variables.keywords = keywords.join(',');
  }
  if (data.location.ignore === false) {
    variables.location = '1';
    if (data.location.place) {
      variables.city = data.location.place.city;
      variables.lng = data.location.place.coords.lng;
      variables.lat = data.location.place.coords.lat;
      variables.countryCode = data.location.place.countryCode;
      variables.postalCode = data.location.place.postalCode;
      if (data.location.place.stateCode || data.location.place.stateCode !== '') {
        variables.stateCode = data.location.place.stateCode;
      }
      variables.street = data.location.place.street;
    }
    if (data.location.radius) {
      variables.radius = data.location.radius;
    }
  } else {
    variables.location = '0';
  }
  if (data.page) {
    variables.page = data.page;
  }
  return qs.stringify(variables);
}

// -------------------------------------------------------------------------------------------------

/**
 * parse query params and create default deal filter
 *
 * @param {string} query
 */
export function parseFilter(query: string): DealsFilterType {
  const variables = getQueryParams();
  const filter: DealsFilterType = {
    ...defaultFilter,
    location: defaultFilter.location ? { ...defaultFilter.location } : null
  };

  const type = variables.type ? variables.type.toUpperCase() : null;

  if (type && Enum.DealType.types.indexOf(type) > -1) {
    filter.type = type;
  }

  let cooperation = (variables.cooperation ? variables.cooperation.toUpperCase() : '')
    .trim()
    .split(',')
    .filter(i => i);
  cooperation = cooperation.filter(coop => Enum.Cooperation.types.indexOf(coop) > -1);

  if (cooperation && cooperation.length > 0) {
    filter.cooperation = cooperation;
  }

  const keywords = (variables.keywords || '')
    .trim()
    .split(',')
    .filter(i => i);

  if (keywords && keywords.length > 0) {
    filter.categoryOrKeyword = { type: 'KEYWORD', label: keywords[0], value: '' };
  }
  if (variables.open === '1') {
    filter.location.open = true;
  }

  const page = parseInt(variables.page || '', 10);
  if (page > 0) {
    filter.page = page;
  }

  return filter;
}

// -------------------------------------------------------------------------------------------------

/**
 *
 * @param {OnChangeEvent<*>} e
 * @param {DealsFilterType} data
 */
export function getFilterUrlAction(
  root: string
): (event: OnChangeEvent<*>, data: DealsFilterType) => void {
  return function updateFilterUrl(event: OnChangeEvent<*>, data: DealsFilterType): void {
    routerHistory.push({
      pathname: root,
      search: '?' + serializeFilter(data)
    });
  };
}
