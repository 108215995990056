// @flow

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import I18n from '_helpers/I18n';

import Form from '../../../_components/Form';
import { DealTypePicker } from './../../Account/MyDealsPage'; // TODO export to extra file
import CooperationPicker from '../CooperationPicker';
import CategoryKeywordAutocomplete from '../CategoryKeywordAutocomplete';

export default function SearchDealsFilter(): React.Node {
  return (
    <div>
      <div className="filter-panel">
        <Navbar className="inline" variant="light" bg="light">
          <div className="toggl with-label">
            <label className="text-secondary">
              <I18n id="type" d="Opportunity type" />
            </label>
            <Form.Field
              className="mr-0 mb-2 mr-sm-2 mb-sm-0 cooperation-picker"
              component={DealTypePicker}
              variant="secondary"
              name="type"
            />
          </div>
        </Navbar>
        <Navbar className="inline" variant="light" bg="light">
          <Form.Field component={CooperationPicker} id="cooperationPicker" name="cooperation" />
        </Navbar>
        <Navbar className="inline category-keyword" variant="light" bg="light">
          {/* $FlowFixMe */}
          <Form.Field
            style={{ paddingTop: 7, paddingBottom: 8 }}
            component={CategoryKeywordAutocomplete}
            id="filter-panel.categoryOrKeyword"
            name="categoryOrKeyword"
            block
          />
        </Navbar>
      </div>
      <div className="pb-2" />
    </div>
  );
}
