// @flow

import * as React from 'react';
import { useState, useEffect } from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Redirect from 'react-router/Redirect';

import Link from '../../_components/Link';
import Layout from '../../_components/Layout';
import { getQueryParam } from '_helpers/router';
import { Context as IdentityContext } from '_helpers/Identity';
import { Card1, Card2 } from '../_components/PricingCards/PricingCards';

export type SubscriptionPlan = 'MONTHLY' | 'ANNUAL';

// -------------------------------------------------------------------------------------------------

export default function PaymentRoot(): React.Node {
  const [currency, setCurrency] = useState('');
  const redirectToURL = getQueryParam('redirectToURL');
  const { identity, refreshIdentity } = React.useContext(IdentityContext);

  if (!redirectToURL && localStorage.getItem('contactURL')) {
    localStorage.removeItem('contactURL');
  }

  useEffect(function getActualCurrency() {
    const lang = localStorage.getItem('locale');
    if (lang === 'cz') {
      setCurrency('CZK');
    } else if (lang === 'sk') {
      setCurrency('EUR');
    } else {
      setCurrency('GBP');
    }
  });

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/" />;
    }
  }, []);

  return (
    <Layout narrow>
      <h1 className="mb-4 mt-4">
        <I18n id="title" d="Upgrade plan" />
      </h1>
      <p className="text-primary">
        <I18n id="subtitl" d="Please subscribe to get access to all premium features" />
      </p>
      <Row className="mb-5 mt-5">
        <Col xs={12} md={6} className="mb-5 mb-md-0">
          <Card1 button={<SubscribeButton plan="MONTHLY" currency={currency} />} />
        </Col>
        <Col xs={12} md={6}>
          <Card2 button={<SubscribeButton plan="ANNUAL" currency={currency} />} />
        </Col>
      </Row>
    </Layout>
  );
}

// -------------------------------------------------------------------------------------------------

function SubscribeButton(props: { plan: SubscriptionPlan, currency: string }): React.Node {
  const { identity, updateIdentity } = React.useContext(IdentityContext);

  function setIdentityAndRedirect() {
    updateIdentity({ ...identity, paymentInProgress: true });

    window.location.href =
      (process.env.REACT_APP_API_HOST || '') +
      '/payment/subscribe?type=' +
      props.plan +
      '&currency=' +
      props.currency;
  }

  return (
    <Link
      onClick={setIdentityAndRedirect}
      className="d-inline btn-subscribe"
      style={{ width: 'auto' }}
      component={Button}
    >
      <I18n id="title" d="Subscribe" />
    </Link>
  );
}

// -------------------------------------------------------------------------------------------------
