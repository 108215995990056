/**
 * @flow
 * @relayHash a7e5ddeca3d4ed57abf26dae53d24582
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type ManageContractPageFormMutationVariables = {|
  contractId: string,
  status: ContractStatus,
  notes?: ?string,
|};
export type ManageContractPageFormMutationResponse = {|
  +contract: {|
    +id: string,
    +status: ?ContractStatus,
    +notes: ?string,
    +deal: {|
      +id: string
    |},
  |}
|};
export type ManageContractPageFormMutation = {|
  variables: ManageContractPageFormMutationVariables,
  response: ManageContractPageFormMutationResponse,
|};
*/

/*
mutation ManageContractPageFormMutation(
  $contractId: ID!
  $status: ContractStatus!
  $notes: String
) {
  contract: updateContract(id: $contractId, status: $status, notes: $notes) {
    id
    status
    notes
    deal {
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'contractId',
        type: 'ID!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'status',
        type: 'ContractStatus!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'notes',
        type: 'String',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = [
      {
        kind: 'LinkedField',
        alias: 'contract',
        name: 'updateContract',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'contractId'
          },
          {
            kind: 'Variable',
            name: 'notes',
            variableName: 'notes'
          },
          {
            kind: 'Variable',
            name: 'status',
            variableName: 'status'
          }
        ],
        concreteType: 'Contract',
        plural: false,
        selections: [
          (v1 /*: any*/),
          {
            kind: 'ScalarField',
            alias: null,
            name: 'status',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'notes',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'deal',
            storageKey: null,
            args: null,
            concreteType: 'Deal',
            plural: false,
            selections: [(v1 /*: any*/)]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ManageContractPageFormMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'ManageContractPageFormMutation',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v2 /*: any*/)
    },
    params: {
      operationKind: 'mutation',
      name: 'ManageContractPageFormMutation',
      id: null,
      text:
        'mutation ManageContractPageFormMutation(\n  $contractId: ID!\n  $status: ContractStatus!\n  $notes: String\n) {\n  contract: updateContract(id: $contractId, status: $status, notes: $notes) {\n    id\n    status\n    notes\n    deal {\n      id\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '4c9a2de41958f98c312e85d3d427d2bb';
module.exports = node;
