// @flow

import cn from 'classnames';
import I18n from '_helpers/I18n';
import * as React from 'react';

export default function ExpandText(props: { text: string, length: number }): React.Node {
  const text = (props.text || '').trim();
  const a = text.substr(0, props.length);
  const b = text.substr(props.length);

  const [open, setOpen] = React.useState(false);
  const toggle = React.useCallback(() => {
    setOpen(open => !open);
  }, []);

  if (!b) {
    return props.text;
  }

  return (
    <div className={cn('expand-text', { open })}>
      {a}
      <span className="expand-text__dots">...</span>
      <span className="expand-text__more">{b}</span>
      <br />
      <a href="#more" onClick={toggle}>
        {open ? <I18n d="Show less" id="lessButton" /> : <I18n d="Read more" id="moreButton" />}
      </a>
    </div>
  );
}
