// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';

const CooperationDescriptions = {
  SPARE_CAPACITY: function SpaceCapacityDescription(): React.Node {
    return (
      <I18n
        d="Common sense dictates that the manufacturing capacity of any company should be utilized at the highest level. This includes human workforce or production lines. So if you have spare resources, why not offer them to your business partners and increase your revenue in the process?"
        id="spareText"
      />
    );
  },
  SHARE_ASSETS: function ShareAssetsDescription(): React.Node {
    return (
      <I18n
        d="Buildings, machines, stockpiles of material or even scrap can be of interest to other manufacturers. Your business partners can utilize your spare resources as an important component of their economic activities."
        id="assetsText"
      />
    );
  },
  SHARE_PROCUREMENT: function ShareAssetsDescription(): React.Node {
    return (
      <I18n
        d="The greater the volume of goods you source, the lower the cost. Isn´t this a compelling reason to cooperate with like-minded companies and share in the procurement processes? It can lead to great savings – not only in procurement costs, but also in transportation and logistics."
        id="procurementText"
      />
    );
  },
  RND_COOPERATION: function ShareAssetsDescription(): React.Node {
    return (
      <I18n
        d="Outside of your proprietary business information, you can also share your R&D resources with other companies. This kind of cooperation may yield strong positive effects on your unit costs of production and many other benefits."
        id="rndText"
      />
    );
  }
};

export default CooperationDescriptions;
