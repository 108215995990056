// @flow

import idx from 'idx';
import * as React from 'react';
import { graphql } from 'react-relay';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Logger from '@app/Logger';
import I18n from '_helpers/I18n';
import Enum from '../_components/Enum';
import Icon from '../_components/Icon';
import Link from '../_components/Link';
import Layout from '../_components/Layout';
import Redirect from 'react-router/Redirect';
import DealList from './_components/DealList';
import DateTime from '../_components/DateTime';
import LoadingContent from './_components/LoadingContent';
import { Query, commitMutationPromise } from '../_helpers/Api';
import { Context as IdentityContext } from '../_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export type DealcontactPageProps = RouteProps<{ companyId: string, dealId: string }>;

// -------------------------------------------------------------------------------------------------

export default function DealcontactPage(props: DealcontactPageProps): React.Node {
  const companyId = idx(props, _ => _.match.params.companyId);
  const dealId = idx(props, _ => _.match.params.dealId);
  const { identity, refreshIdentity, loading } = React.useContext(IdentityContext);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dealId && proposeContract(dealId);
    }, 3000);

    return () => {
      timer && clearTimeout(timer);
    };
  });

  React.useEffect(() => {
    if (refreshIdentity() && (!identity || !identity.company.paying)) {
      return <Redirect to="/error/403" />;
    }
  }, []);

  if (loading) {
    return <LoadingContent />;
  }

  return (
    <Layout.Common narrow>
      <Query name="companyDetail" variables={{ companyId }}>
        {({ loading, error, data }) => {
          const company = data && data.company;
          const deals = company && company.deals && company.deals.edges;

          if (loading) {
            return <LoadingContent />;
          }

          if (error) {
            return <Redirect to="/error/500" />;
          }

          if (!company) {
            return <Redirect to="/error/404" />;
          }

          const contact = company.contact;

          const email = contact && (contact.contactEmail ? contact.contactEmail : contact.email);

          return (
            <>
              <Row>
                <Col sm={6} lg={8} className="mb-3">
                  <Link back className="d-block mb-3">
                    <Icon name="arrow-left" className="mr-3" />
                    <I18n id="backLink" d="Back" />
                  </Link>
                  {company.logo && company.logo.url && (
                    <I18n id="logoAlt" d="Company brand">
                      {alt => (
                        <div className="company-logo image mb-3">
                          <div
                            className="image-wrapper"
                            style={{
                              backgroundImage: `url(${company.logo.url})`
                            }}
                          />
                        </div>
                      )}
                    </I18n>
                  )}

                  <div>
                    <h1 className={`mb-3 ${company.logo && company.logo.url ? 'mt-2' : 'mt-5'}`}>
                      {company.name}
                    </h1>
                    {company.info && <p className="text-justify">{company.info}</p>}
                  </div>

                  {company.specialization && (
                    <>
                      <h5>
                        <I18n id="specializationTitle" d="Specialization" />
                      </h5>
                      <p>{company.specialization}</p>
                    </>
                  )}

                  {company.certificates && (
                    <>
                      <h5>
                        <I18n id="certificatesTitle" d="Certificates" />
                      </h5>
                      <p>{company.certificates}</p>
                    </>
                  )}

                  {company.categories.length > 0 && (
                    <>
                      <h5>
                        <I18n id="categoriesTitle" d="Categories" />
                      </h5>
                      <ul>
                        {company.categories.map(category => (
                          <li key={category.id}>{category.name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </Col>
                <Col sm={6} lg={4} className="col p-5 bg-primary-light">
                  <dl>
                    <dt>
                      <I18n id="addressTitle" d="Address" />
                    </dt>
                    <dd>
                      {company.street}
                      <br />
                      {`${company.zip} ${company.city}`}
                      <br />
                      {company.country && <Enum.Country v={company.country} />}
                    </dd>

                    <dt>
                      <I18n id="vatTitle" d="VAT Number" />
                    </dt>
                    <dd>
                      {company.vat}
                      <br />
                      <Link to="http://ec.europa.eu/taxation_customs/vies/" blank>
                        <I18n id="vatLink" d="Check VAT number" />
                      </Link>
                    </dd>

                    {company.web && (
                      <>
                        <dt>
                          <I18n id="webTitle" d="Company Web" />
                        </dt>
                        <dd>
                          <a href={company.web} target="_blank" rel="noopener noreferrer">
                            {company.web}
                          </a>
                        </dd>
                      </>
                    )}

                    {company.contact && (
                      <>
                        <dt>
                          <I18n id="contactPerson" d="Contact" />
                        </dt>
                        <dd>
                          {`${contact.firstName} ${contact.lastName}`}
                          {contact.phone && (
                            <>
                              <br />
                              <a href={`tel:${contact.phone}`} target="_top">
                                {contact.phone}
                              </a>
                            </>
                          )}
                          {email && (
                            <>
                              <br />
                              <Link to={`mailto:${email}`} blank>
                                {email}
                              </Link>
                            </>
                          )}

                          {contact.messaging &&
                            contact.messaging.map(
                              (provider, key) =>
                                // TODO: key
                                provider.value && (
                                  <div key={key}>
                                    <Icon name={provider.type.toLowerCase()} className="mr-2" />
                                    {provider.value}
                                  </div>
                                )
                            )}

                          {contact.linkedin && (
                            <>
                              <br />
                              <Icon name="linkedin" className="mr-2" />
                              <Link to={contact.linkedin} blank>
                                {contact.linkedin
                                  .split('/')
                                  .filter(i => i)
                                  .pop()}
                              </Link>
                            </>
                          )}
                        </dd>
                      </>
                    )}

                    <dt>
                      <I18n id="registeredTitle" d="Registered" />
                    </dt>
                    <dd>
                      <DateTime.Date value={company.created} />
                    </dd>

                    <dt>
                      <I18n id="successfullContracts" d="Successfull Replies" />
                    </dt>
                    <dd>{company.succeededContracts}</dd>

                    <dt>
                      <I18n id="totalContracts" d="Total Replies" />
                    </dt>
                    <dd>{company.totalContracts}</dd>
                  </dl>
                </Col>
              </Row>
              {deals && deals.length > 0 && (
                <Row>
                  <Col className="mb-5 mt-5">
                    <h1 className="mb-3">
                      <I18n id="openDealsTitle" d="Open Opportunities" />
                    </h1>
                    <DealList items={deals} />
                  </Col>
                </Row>
              )}
            </>
          );
        }}
      </Query>
    </Layout.Common>
  );
}

function proposeContract(dealId: string): void {
  commitMutationPromise({
    mutation: proposeContractMutation,
    variables: { dealId }
  })
    .then((response: { contract: { deal: { summary: string } } }) => ({
      response,
      error: undefined
    }))
    .catch(error => ({ error, response: undefined }))
    .then(({ response, error }) => {
      if (!response || error) {
        Logger.error(`Reply proposition failed for Opportunity '${dealId}'`);
      } else {
        Logger.error(`New Reply Proposed: '${response.contract.deal.summary}'`);
      }
    });
}

const proposeContractMutation = graphql`
  mutation CompanyPageProposeContractMutation($dealId: ID!) {
    contract: proposeContract(id: $dealId) {
      id
      deal {
        id
        summary
      }
    }
  }
`;
