// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import type { OnChangeEvent } from '../../../_components/Form';
import type { FileInterface } from '../../../_components/Form/_components/FileInput/FileInput';

import CategoriesAutocomplete from '../../_components/CategoriesAutocomplete';
import Form, {
  TextareaInput,
  LocationInput,
  FormConsumer,
  ImageInput,
  TextInput
} from '../../../_components/Form';
import { placeToString } from '../../../_components/GoogleAutocompleteInput';

// -------------------------------------------------------------------------------------------------

export type CompanyFormData = {
  categories: Array<NACECategory>,
  specialization: null | string,
  logo: null | FileInterface,
  country: null | string,
  street: null | string,
  state: null | string,
  name: null | string,
  info: null | string,
  city: null | string,
  zip: null | string,
  web: null | string,
  vat: null | string,
  // TODO: get gps from location
  gps: null | { lng: number, lat: number },
  certificates: null | string,
  address: null | string
};

export type CompanyFormProps = {
  defaultValue?: $Shape<CompanyFormData>,
  onSubmit: CompanyFormData => void,
  disabled?: boolean
};

const companyFormValidationRules = {
  vat: { required: true, vatNumber: true },
  specialization: { maxLength: 500 },
  certificates: { maxLength: 500 },
  categories: { required: true },
  gps: { required: true }, // TODO: create custom validation - IF ONE-OF [country, city, zip...] IS EMPTY => err
  name: { required: true },
  info: { maxLength: 500 },
  web: { web: true },
  address: { required: true } // computed value
};

const defaultCompanyForm: CompanyFormData = {
  specialization: null,
  certificates: null,
  categories: [],
  address: null, // not sending to api - its just for displaying computed value from partials
  country: null,
  street: null,
  state: null,
  logo: null,
  name: null,
  info: null,
  city: null,
  web: null,
  zip: null,
  vat: null, // TODO: get gps from location
  gps: null
};

// -------------------------------------------------------------------------------------------------

export default function CompanyForm(props: CompanyFormProps): React.Node {
  const { disabled, defaultValue, onSubmit, ...rest } = props;

  const data = React.useMemo(
    () => ({
      ...defaultCompanyForm,
      ...(defaultValue || {}),
      address: defaultValue ? placeToString(decoratePlace(defaultValue)) : null
    }),
    [defaultValue]
  );

  return (
    <Form
      {...rest}
      validationRules={companyFormValidationRules}
      onChangeDecorator={onChangeDecorator}
      onSubmit={promptSubmit(onSubmit)}
      defaultValue={data}
      disabled={disabled}
    >
      <Row>
        <Col xs={12} md={8} lg={9}>
          <BForm.Group controlId="companyForm.name">
            <BForm.Label>
              <I18n id="nameLabel" d="Legal name" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <I18n id="namePlaceholder" d="Enter the name of your company">
              {placeholder => (
                <Form.Field
                  placeholder={placeholder.value}
                  component={TextInput}
                  name="name"
                  type="text"
                />
              )}
            </I18n>
          </BForm.Group>

          <BForm.Group controlId="companyForm.address">
            <BForm.Label>
              <I18n id="addressLabel" d="Address" />
              <span className="form-label-required"> *</span>
            </BForm.Label>
            <Form.Field name="address" component={AddressPicker} />
          </BForm.Group>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <BForm.Group controlId="companyForm.location">
            <BForm.Label>
              <I18n id="avatarLabel" d="Company logo" />
            </BForm.Label>
            <I18n id="avatarPlaceheolder" d="Company logo">
              {placeholder => (
                /* $FlowFixMe */
                <Form.Field
                  placeholder={placeholder.value}
                  component={ImageInput}
                  accept="avatar"
                  name="logo"
                />
              )}
            </I18n>
          </BForm.Group>
        </Col>
      </Row>

      <BForm.Group controlId="companyForm.categories">
        <BForm.Label>
          <I18n id="categoriesLabel" d="Categories" />
          <span className="form-label-required"> *</span>
        </BForm.Label>
        <I18n id="categoriesPlaceholder" d="Search">
          {placeholder => (
            // $FlowFixMe
            <Form.Field
              component={CategoriesAutocomplete}
              placeholder={placeholder.value}
              name="categories"
            />
          )}
        </I18n>
      </BForm.Group>

      <BForm.Group controlId="companyForm.vat">
        <BForm.Label>
          <I18n id="vatLabel" d="VAT" />
          <I18n
            id="vatDescription"
            d=" (if not VAT registered, add GB followed by your company number)"
          />
          <span className="form-label-required"> *</span>
        </BForm.Label>
        <I18n id="vatPlaceholder" d="XX1234567890">
          {placeholder => (
            // $FlowFixMe
            <Form.Field placeholder={placeholder.value} component={TextInput} name="vat" />
          )}
        </I18n>
      </BForm.Group>

      <BForm.Group controlId="companyForm.web">
        <BForm.Label>
          <I18n id="webLabel" d="Company web" />
        </BForm.Label>
        <I18n id="webPlaceholder" d="Link to your company website">
          {placeholder => (
            <Form.Field placeholder={placeholder.value} component={TextInput} name="web" />
          )}
        </I18n>
      </BForm.Group>

      <Row>
        <Col md={6}>
          <BForm.Group controlId="companyForm.info">
            <BForm.Label>
              <I18n id="infoLabel" d="Company info" />
            </BForm.Label>
            <I18n id="infoPlaceholder" d="Provide a short description of your company">
              {placeholder => (
                <Form.Field
                  placeholder={placeholder.value}
                  component={TextareaInput}
                  id="companyForm.info"
                  name="info"
                  rows={10}
                />
              )}
            </I18n>
          </BForm.Group>
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <BForm.Group controlId="companyForm.specialization">
                <BForm.Label>
                  <I18n id="specializationLabel" d="Specialization" />
                </BForm.Label>
                <I18n id="specializationPlaceholder" d="What does your company specialize in?">
                  {placeholder => (
                    <Form.Field
                      placeholder={placeholder.value}
                      id="companyForm.specialization"
                      component={TextareaInput}
                      name="specialization"
                    />
                  )}
                </I18n>
              </BForm.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <BForm.Group controlId="companyForm.certificates">
                <BForm.Label>
                  <I18n id="certificatesLabel" d="Certificates" />
                </BForm.Label>
                <I18n id="certificatesPlaceholder" d="Enter your certificates">
                  {placeholder => (
                    <Form.Field
                      placeholder={placeholder.value}
                      id="companyForm.certificates"
                      component={TextareaInput}
                      name="certificates"
                    />
                  )}
                </I18n>
              </BForm.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <FormConsumer>
        {({ isChanged, reset, data }) => (
          <>
            <Button type="submit" disabled={disabled || !isChanged}>
              <I18n id="submitButton" d="Save" />
            </Button>

            <Button
              disabled={disabled || !isChanged}
              variant="outline-dark"
              className="ml-2"
              onClick={reset}
            >
              <I18n id="cancelButton" d="Cancel" />
            </Button>
          </>
        )}
      </FormConsumer>
    </Form>
  );
}

// -------------------------------------------------------------------------------------------------

function onChangeDecorator(event: OnChangeEvent<*>, data: CompanyFormData): CompanyFormData {
  if (event.name === 'country' && data.country !== 'US' && data.state !== null) {
    return { ...data, state: null };
  }
  return data;
}

// -------------------------------------------------------------------------------------------------

function decoratePlace(data: CompanyFormData): Place {
  return {
    countryCode: data.country || '',
    stateCode: data.state || '',
    postalCode: data.zip || '',
    street: data.street || '',
    city: data.city || '',
    state: '',
    country: '',
    coords: {
      lng: 0,
      lat: 0,
      ...(data.gps || {})
    }
  };
}

// -------------------------------------------------------------------------------------------------

function decorateData(place: null | Place): { [$Keys<CompanyFormData>]: * } {
  if (place) {
    return {
      state: place.countryCode === 'US' ? place.stateCode : null,
      country: place.countryCode,
      zip: place.postalCode,
      street: place.street,
      gps: place.coords,
      city: place.city
    };
  }
  return {};
}

// -------------------------------------------------------------------------------------------------

const AddressPicker = () => {
  return (
    <I18n id="addressPlaceholder" d="Enter Company location">
      {placeholder => (
        <LocationInput
          placeholder={placeholder.value}
          decoratePlace={decoratePlace}
          decorateData={decorateData}
          name="address"
        />
      )}
    </I18n>
  );
};

// -------------------------------------------------------------------------------------------------

function promptSubmit(
  onSubmit: CompanyFormData => void,
  form: { reset: () => void }
): CompanyFormData => void {
  return function(formData: CompanyFormData, form: any): void {
    const mutationData = {
      ...formData,
      address: undefined // remove computed property (its display only)
    };
    onSubmit(mutationData, form);
  };
}
