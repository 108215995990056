// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import BForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';

import Link from '../../_components/Link';
import { getActiveLocale } from '_helpers/I18n/I18n';
import toastr from '../../_helpers/toastr';
import { AuthApi } from '../../_helpers/Api';
import Layout from '../../_components/Layout';
import { routerHistory } from '../../_helpers/router';
import Form, { PasswordInput } from '../../_components/Form';
import { Context as I18nContext } from '../../_helpers/I18n';

// -------------------------------------------------------------------------------------------------

export type AuthResetPageProps = RouteProps<{ token: string }>;

// -------------------------------------------------------------------------------------------------

const resetValidation = {
  password: { required: true, minLength: 8, hasDigit: true, maxLength: 50 }
};

export default function AuthResetPage(props: AuthResetPageProps): React.Node {
  const token = props.match.params.token;
  const values = queryString.parse(props.location.search);
  const { setLocale } = React.useContext(I18nContext);

  if (getActiveLocale() !== values.lang.toLowerCase()) {
    setLocale(values.lang.toLowerCase());
  }

  return (
    <AuthApi action="reset">
      {(action, { loading }) => (
        <Layout.Auth>
          <h1 className="mb-4">
            <I18n id="title" d="Reset Password" />
          </h1>

          <Form
            onSubmit={handleSubmit(action, token)}
            validationRules={resetValidation}
            disabled={loading}
          >
            <BForm.Group>
              <I18n id="passwordPlaceholder" d="Password">
                {placeholder => (
                  // $FlowFixMe
                  <Form.Field
                    placeholder={placeholder.value}
                    autoComplete="new-password"
                    component={PasswordInput}
                    name="password"
                  />
                )}
              </I18n>
            </BForm.Group>
            <BForm.Group>
              <Button variant="primary" block type="submit" disabled={loading}>
                <I18n id="submit" d="Reset password" />
              </Button>
            </BForm.Group>
          </Form>

          <p className="text-left">
            <I18n id="backToText" d="Back to" />{' '}
            <Link to="/auth/login">
              <I18n id="backToLink" d="Sign In" />
            </Link>
          </p>
        </Layout.Auth>
      )}
    </AuthApi>
  );
}

// -------------------------------------------------------------------------------------------------

const handleSubmit = (action, token) => data => {
  action && action({ ...data, token }).then(handleResponse);
};

// set identity or display errors
const handleResponse = response => {
  let message = null;
  let err = false;
  if (response && response.ok) {
    message = <I18n id="successMessage" d="Password changed" />;
    routerHistory.replace('/account');
  } else {
    err = true;
    message = (
      <I18n
        id="errMessage$"
        d={{
          UNKNOWN_ERROR: 'Ooops! Something went wrong.',
          INVALID_EMAIL: 'Plese enter valid email',
          INVALID_TOKEN: 'Invalid request, ask for reset link again',
          TOKEN_NOT_PROVIDED:
            'New Password was set but we are not able to sign-in you automatically'
        }}
      >
        {({ d }) => {
          return (response && response.code && d[response.code]) || d.UNKNOWN_ERROR;
        }}
      </I18n>
    );

    if (response && response.code === 'TOKEN_NOT_PROVIDED') {
      routerHistory.push('/auth/login');
    }
  }
  toastr({
    type: err ? 'error' : 'success',
    message
  });
};
