// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Link from '../../_components/Link';

import BBCode from '../../_components/BBCode';
import Layout from '../../_components/Layout';
import history from '../../_helpers/router/routerHistory';
import { Context as Identity } from '_helpers/Identity';

export default function PaymentError(): React.Node {
  const dealURL = localStorage.getItem('contactURL') ? '/subscribe?redirectToURL=1' : '/subscribe';
  const { loading: refreshing, identity, updateIdentity } = React.useContext(Identity);
  const [oldIdentity, setIdentity] = React.useState(null);

  React.useEffect(() => {
    if (!oldIdentity) {
      if (!identity.paymentInProgress) {
        return history.push('/subscribe');
      }
      setIdentity(identity);
    } else {
      updateIdentity({ ...identity, paymentInProgress: false });
    }
  }, [refreshing]);

  return (
    <Layout verticalCenter>
      <Row className="mt-5 mb-5 d-flex justify-content-center align-items-center">
        <Col lg={4} sm={8} xs={12} className="text-center">
          <Card className="p-5">
            <h1 className="mb-4 mt-4">
              <I18n id="title" d="Oops!" />
            </h1>
            <p className="text-center overflow-hidden">
              <I18n
                d='Payment process has[br /]been [span class="p-1 bg-danger"]declined[/span][br /]'
                id="message"
              >
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </p>
            <Link to={dealURL} component={Button} variant="primary" className="text-center mt-3">
              <I18n d="Try again" id="tryAgain">
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </Link>
            <Link to="/contact" component={Button} variant="secondary" className="text-center mt-3">
              <I18n d="Contact us" id="contact">
                {({ value }) => <BBCode>{value}</BBCode>}
              </I18n>
            </Link>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
