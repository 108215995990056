// @flow

import * as React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import I18n from '_helpers/I18n';
import Icon from '../../../_components/Icon';

export default function Quotes(): React.Node {
  return (
    <I18n
      id="quotes$"
      d={{
        q1: 'The best way to predict the future is to create it.',
        q2:
          "Any skilled engineer can take control remotely of any connected 'thing'. Society has not yet realized the incredible scenarios this capability creates.",
        q3:
          'The first rule of any technology used in a business is that automation applied to an efficient operation will magnify the efficiency. The second is that automation applied to an inefficient operation will magnify the inefficiency.',
        q4:
          'We live in a society exquisitely dependent on science and technology, in which hardly anyone knows anything about science and technology.',
        q5: 'A Problem Well-stated is Half-solved.',
        q6:
          "If you're trying to create a company, it's like baking a cake. You have to have all the ingredients in the right proportion.",
        q7:
          "Some people don't like change, but you need to embrace change if the alternative is disaster.",
        q8:
          'The key to making things affordable is design and technology improvements, as well as scale.',
        q9: 'When something is important enough, you do it even if the odds are not in your favor.',
        q10: 'Quality means doing it right when no one is looking.',
        q11: 'Failure is simply the opportunity to begin again, this time more intelligently.',
        q12:
          'The competitor to be feared is one who never bothers about you at all, but goes on making his own business better all the time.',
        q13: 'Whether you think you can, or you think you can’t–you’re right.',
        q14: 'You can’t build a reputation on what you are going to do.',
        q15: 'Vision without execution is just hallucination.',
        q16: 'Nothing is particularly hard if you divide it into small jobs.',
        q17:
          'Growth is painful. Change is painful. But, nothing is as painful as staying stuck where you do not belong.',
        q18:
          'I do not think you can name many great inventions that have been made by married men.',
        q19:
          "There is always space for improvement, no matter how long you've been in the business.",
        q20:
          'Digital is the main reason just over half of the companies on the Fortune 500 have disappeared since the year 2000.',
        q21: 'Courage is half way to success.'
      }}
    >
      {({ d }) => (
        <Carousel
          className="quotes mt-5 mb-5 d-flex flex-row"
          nextIcon={<Icon name="arrow-right" />}
          prevIcon={<Icon name="arrow-left" />}
        >
          {Object.keys(d).map(
            (q, key: number): React.Node => (
              <Carousel.Item key={key}>
                <div className="p-5 d-block">
                  <div className="quote-card text-center p-5 text-strong">
                    <Icon name="quote" size={62} />
                    <h5 className="text-primary mb-4">
                      <PersonName v={q} />
                    </h5>
                    <blockquote>{d[q]}</blockquote>
                  </div>
                </div>
              </Carousel.Item>
            )
          )}
        </Carousel>
      )}
    </I18n>
  );
}

const PersonName = (props: { v: string }): React.Node => {
  return (
    <I18n
      id="person$"
      d={{
        q1: 'Abraham Lincoln',
        q2: 'Andre Kudelski',
        q3: 'Bill Gates',
        q4: 'Carl Sagan',
        q5: 'Charles Kettering',
        q6: 'Elon Musk',
        q7: 'Elon Musk',
        q8: 'Elon Musk',
        q9: 'Elon Musk',
        q10: 'Henry Ford',
        g11: 'Henry Ford',
        q12: 'Henry Ford',
        q13: 'Henry Ford',
        q14: 'Henry Ford',
        q15: 'Henry Ford',
        q16: 'Henry Ford',
        q17: 'Narayana Murthy',
        q18: 'Nikola Tesla',
        q19: 'Oscar De La Hoya',
        q20: 'Pierre Nanterme',
        q21: 'Tomas Bata'
      }}
    >
      {({ d }) => d[props.v] || ''}
    </I18n>
  );
};
