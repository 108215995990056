/**
 * @flow
 * @relayHash cdb6816dba7839ffc18da6edfad1b70c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealStatus = "CLOSED" | "OPEN" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type LocationInput = {|
  radius?: ?number,
  lng?: ?number,
  lat?: ?number,
|};
export type SearchDealsPageQueryVariables = {|
  cooperation?: ?$ReadOnlyArray<DealCooperation>,
  location?: ?LocationInput,
  keywords?: ?$ReadOnlyArray<any>,
  status?: ?DealStatus,
  type?: ?DealType,
  category?: ?string,
  first: number,
  offset?: ?number,
|};
export type SearchDealsPageQueryResponse = {|
  +search: {|
    +results: {|
      +pageInfo: {|
        +startCursor: ?number,
        +endCursor: ?number,
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
      |},
      +edges: $ReadOnlyArray<{|
        +cursor: number,
        +node: {|
          +id: string,
          +summary: string,
          +type: DealType,
          +cooperation: DealCooperation,
          +status: DealStatus,
          +category: {|
            +id: string,
            +name: string,
          |},
          +keywords: $ReadOnlyArray<any>,
          +created: any,
          +description: string,
          +unseenContracts: {|
            +total: number
          |},
        |},
      |}>,
      +total: number,
    |}
  |}
|};
export type SearchDealsPageQuery = {|
  variables: SearchDealsPageQueryVariables,
  response: SearchDealsPageQueryResponse,
|};
*/

/*
query SearchDealsPageQuery(
  $cooperation: [DealCooperation!]
  $location: LocationInput
  $keywords: [Keyword!]
  $status: DealStatus
  $type: DealType
  $category: ID
  $first: Int!
  $offset: Int
) {
  search {
    results: dealsConnection(cooperation: $cooperation, location: $location, category: $category, keywords: $keywords, offset: $offset, status: $status, first: $first, type: $type, mine: false) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          summary
          type
          cooperation
          status
          category {
            id
            name
          }
          keywords
          created
          description
          unseenContracts: contracts(seen: false) {
            total
          }
          __typename
        }
      }
      total
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'cooperation',
        type: '[DealCooperation!]',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'location',
        type: 'LocationInput',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'keywords',
        type: '[Keyword!]',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'status',
        type: 'DealStatus',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'type',
        type: 'DealType',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'category',
        type: 'ID',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int!',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'offset',
        type: 'Int',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'Variable',
      name: 'cooperation',
      variableName: 'cooperation'
    },
    v2 = {
      kind: 'Variable',
      name: 'keywords',
      variableName: 'keywords'
    },
    v3 = {
      kind: 'Variable',
      name: 'location',
      variableName: 'location'
    },
    v4 = {
      kind: 'Variable',
      name: 'status',
      variableName: 'status'
    },
    v5 = {
      kind: 'Variable',
      name: 'type',
      variableName: 'type'
    },
    v6 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v7 = {
      kind: 'ScalarField',
      alias: null,
      name: 'total',
      args: null,
      storageKey: null
    },
    v8 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'pageInfo',
        storageKey: null,
        args: null,
        concreteType: 'PageInfo',
        plural: false,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'startCursor',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'endCursor',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'hasNextPage',
            args: null,
            storageKey: null
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'hasPreviousPage',
            args: null,
            storageKey: null
          }
        ]
      },
      {
        kind: 'LinkedField',
        alias: null,
        name: 'edges',
        storageKey: null,
        args: null,
        concreteType: 'DealEdge',
        plural: true,
        selections: [
          {
            kind: 'ScalarField',
            alias: null,
            name: 'cursor',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'node',
            storageKey: null,
            args: null,
            concreteType: 'Deal',
            plural: false,
            selections: [
              (v6 /*: any*/),
              {
                kind: 'ScalarField',
                alias: null,
                name: 'summary',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'type',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'cooperation',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'status',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'category',
                storageKey: null,
                args: null,
                concreteType: 'NACECategory',
                plural: false,
                selections: [
                  (v6 /*: any*/),
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'name',
                    args: null,
                    storageKey: null
                  }
                ]
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'keywords',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'created',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'description',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: 'unseenContracts',
                name: 'contracts',
                storageKey: 'contracts(seen:false)',
                args: [
                  {
                    kind: 'Literal',
                    name: 'seen',
                    value: false
                  }
                ],
                concreteType: 'ContractsConnection',
                plural: false,
                selections: [(v7 /*: any*/)]
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: '__typename',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      },
      (v7 /*: any*/)
    ],
    v9 = [
      {
        kind: 'Variable',
        name: 'category',
        variableName: 'category'
      },
      (v1 /*: any*/),
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first'
      },
      (v2 /*: any*/),
      (v3 /*: any*/),
      {
        kind: 'Literal',
        name: 'mine',
        value: false
      },
      {
        kind: 'Variable',
        name: 'offset',
        variableName: 'offset'
      },
      (v4 /*: any*/),
      (v5 /*: any*/)
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'SearchDealsPageQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'search',
          storageKey: null,
          args: null,
          concreteType: 'SearchQueries',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: 'results',
              name: '__SearchDeals_results_connection',
              storageKey: null,
              args: [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/)
              ],
              concreteType: 'DealsConnection',
              plural: false,
              selections: (v8 /*: any*/)
            }
          ]
        }
      ]
    },
    operation: {
      kind: 'Operation',
      name: 'SearchDealsPageQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'search',
          storageKey: null,
          args: null,
          concreteType: 'SearchQueries',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: 'results',
              name: 'dealsConnection',
              storageKey: null,
              args: (v9 /*: any*/),
              concreteType: 'DealsConnection',
              plural: false,
              selections: (v8 /*: any*/)
            },
            {
              kind: 'LinkedHandle',
              alias: 'results',
              name: 'dealsConnection',
              args: (v9 /*: any*/),
              handle: 'connection',
              key: 'SearchDeals_results',
              filters: ['cooperation', 'location', 'categories', 'keywords', 'status', 'type']
            }
          ]
        }
      ]
    },
    params: {
      operationKind: 'query',
      name: 'SearchDealsPageQuery',
      id: null,
      text:
        'query SearchDealsPageQuery(\n  $cooperation: [DealCooperation!]\n  $location: LocationInput\n  $keywords: [Keyword!]\n  $status: DealStatus\n  $type: DealType\n  $category: ID\n  $first: Int!\n  $offset: Int\n) {\n  search {\n    results: dealsConnection(cooperation: $cooperation, location: $location, category: $category, keywords: $keywords, offset: $offset, status: $status, first: $first, type: $type, mine: false) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        cursor\n        node {\n          id\n          summary\n          type\n          cooperation\n          status\n          category {\n            id\n            name\n          }\n          keywords\n          created\n          description\n          unseenContracts: contracts(seen: false) {\n            total\n          }\n          __typename\n        }\n      }\n      total\n    }\n  }\n}\n',
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: null,
            direction: 'forward',
            path: ['search', 'results']
          }
        ]
      }
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = 'f1ae4a2aca3aa475dca8cee8cc9d0927';
module.exports = node;
