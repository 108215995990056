// @flow

import * as React from 'react';
import cn from 'classnames';
import toastr from '../../../_helpers/toastr';
import I18n from '_helpers/I18n';
import { getActiveLocale } from '_helpers/I18n/I18n';

import DatePicker, { registerLocale } from 'react-datepicker';
import cz from 'date-fns/locale/cs';
import sk from 'date-fns/locale/sk';
import en from 'date-fns/locale/en-GB';

import 'react-datepicker/dist/react-datepicker.css';

import type { OnChangeEvent } from '../types';

export default function DateInput(props: {
  onChange: (OnChangeEvent<Date>) => void,
  onClick?: (OnClickEvent<Date>) => void,
  created: null | Date,
  className?: string,
  isInvalid?: boolean,
  value: null | Date,
  disabled: boolean,
  populated: Boolean,
  paying: boolean,
  name: string
}): React.Node {
  const {
    onChange,
    name,
    value,
    created,
    className,
    isInvalid,
    paying,
    disabled,
    populated
  } = props;
  const max = !paying
    ? created
      ? new Date(new Date(created).setMonth(created.getMonth() + 6))
      : new Date(new Date().setMonth(new Date().getMonth() + 6))
    : null;
  const min = created ? new Date(created) : new Date();
  if (min) {
    min.setHours(min.getHours() + 168, min.getMinutes(), min.getSeconds(), min.getMilliseconds());
  }
  const onlyDate =
    max && min && !paying
      ? new Date().getTime() > min.getTime() && new Date().getTime() < new Date(max).getTime()
      : false;
  const exDate = max && !paying ? new Date().getTime() > new Date(max).getTime() : false;
  const firstUpdate = React.useRef(true);

  const handler = React.useCallback(
    (value: null | Date) => {
      if (onlyDate) {
        toastr({
          type: 'warning', //info, options
          message: (
            <I18n
              d="Validity of your opportunity is up to six months after your registration as a non-paying user."
              id="onlyDateMessage"
            />
          )
        });
      }
      if (exDate) {
        toastr({
          type: 'warning', //info, options
          message: (
            <I18n d="You cannot edit this opportunity as a non-paying user." id="exDateMessage" />
          )
        });
      }
      const event = { name, value: value || null };
      onChange(event);
    },
    [name, onChange]
  );

  React.useEffect(() => {
    if (onlyDate) {
      toastr({
        type: 'warning', //info, options
        message: (
          <I18n
            d="Validity of your opportunity is up to six months after your registration as a non-paying user."
            id="onlyDateMessage"
          />
        )
      });
    }
    if (exDate) {
      toastr({
        type: 'warning', //info, options
        message: (
          <I18n d="You cannot edit this opportunity as a non-paying user." id="exDateMessage" />
        )
      });
    }
  }, [firstUpdate]);

  const readOnly = true;

  const DateInput = React.forwardRef((props, ref) => (
    <input
      ref={ref}
      className={cn('form-control', { 'is-invalid': isInvalid }, className || '', 'date-input', {
        disabled: !populated || (exDate && disabled)
      })}
      onClick={props.onClick}
      value={props.value}
      disabled={!populated || (exDate && disabled)}
      type="text"
      readOnly={readOnly}
    />
  ));

  DateInput.displayName = 'DateInput';
  const ref = React.createRef();

  registerLocale('cz', cz);
  registerLocale('sk', sk);
  registerLocale('en', en);

  return (
    <DatePicker
      className={cn('form-control', { 'is-invalid': isInvalid }, className || '')}
      locale={getActiveLocale()}
      dateFormat="dd.MM.yyyy" // TODO: Make configurable
      onSelect={handler}
      selected={value}
      disabled={!populated || (exDate && disabled)}
      minDate={new Date()}
      maxDate={max}
      startDate={new Date()}
      name={name}
      customInput={<DateInput props={props} ref={ref} />}
      {...props}
    />
  );
}
