// @flow
import I18n from '_helpers/I18n';
import * as React from 'react';
import cn from 'classnames';
import { Typeahead as TAInput } from 'react-bootstrap-typeahead';

import type { OnChangeEvent } from '../';

export type TypeaheadOption = { value: *, label: string | null | React.Element<*> };
export type TypeaheadOptions = Array<TypeaheadOption>;

export type TypeaheadProps = {|
  onChange: (OnChangeEvent<TypeaheadOptions>) => void,
  value: null | TypeaheadOptions,
  options: TypeaheadOptions,
  onSearch?: string => void,
  placeholder?: string,
  disabled?: boolean,
  multiple?: boolean,
  className?: string,
  loading?: boolean,
  name: string,
  id: string
|};

export default function Typeahead(props: TypeaheadProps): React.Node {
  const { name, id, placeholder, onChange, onSearch, options, value, disabled, ...rest } = props;

  return (
    <TAInput
      {...rest}
      emptyLabel={<I18n id="noMatchesText" d="No matches..." />}
      className={cn('typeahead control-search', {
        disabled: disabled
      })}
      onChange={onChangeHandler(onChange, name)}
      onInputChange={stateHandler(onSearch)}
      placeholder={placeholder}
      options={options}
      disabled={disabled}
      labelKey="label"
      selected={value}
      name={name}
      id={id}
      selectHintOnEnter
    />
  );
}

function onChangeHandler(
  onChange: (OnChangeEvent<TypeaheadOptions>) => void,
  name: string
): TypeaheadOptions => void {
  return function handleTypeaheadOnChange(value: TypeaheadOptions) {
    onChange && onChange({ name, value });
  };
}

function stateHandler(onSearch: ?(string) => void): ?(string) => void {
  return (
    onSearch &&
    function(search: string) {
      onSearch && onSearch(search);
    }
  );
}
