// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Link from '../../_components/Link';
import Layout from '../../_components/Layout';
import BBCode from '../../_components/BBCode';

// -------------------------------------------------------------------------------------------------

export default function ErrorLayout(props: { children: React.Node }): React.Node {
  return (
    <Layout.Common verticalCenter className="error-layout">
      <Row>
        {/* Specific content */}
        <Col md={6} className="d-flex align-items-center">
          <div>
            {props.children}
            <Link to="/" component={Button}>
              <I18n d="Back to homepage" id="button" />
            </Link>
          </div>
        </Col>

        {/* Generic info card */}
        <Col md={6} className="d-flex align-items-center justify-content-center">
          <div className="error-layout__more">
            <h3 className="mb-3">
              <I18n id="moreTitle" d="What to do now?" />
            </h3>
            <ul className="text-medium mb-0">
              <li className="mb-2">
                <I18n id="more1" d="Make sure you have't made a mistake when typing the address" />
              </li>
              <li className="mb-2">
                <I18n id="more2" d="Try reload the page by hitting the refresh button" />
              </li>
              <li>
                <I18n
                  d='[a href="mailto:support@zync.space"]Contact our support[/a] team'
                  id="more3"
                >
                  {({ value }) => <BBCode>{value}</BBCode>}
                </I18n>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Layout.Common>
  );
}
