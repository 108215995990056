// @flow

import * as React from 'react';
import Container from 'react-bootstrap/Container';

import Layout from '../../_components/Layout';
import Section1 from './_components/Section1';
import Section2 from './_components/Section2';
import Section3 from './_components/Section3';
import Section4 from './_components/Section4';
import Section5 from './_components/Section5';
import Footer from '../../_components/Footer';

import footerBg from '../../_assets/images/footer-buildings.svg';
import JoinBanner from '../_components/JoinBanner';
import Quotes from './_components/Quotes';

import queryString from 'query-string';
import { Context as I18nContext } from '../../_helpers/I18n';
import { getActiveLocale } from '_helpers/I18n/I18n';

// -------------------------------------------------------------------------------------------------

export default function AuthPage(props: *): React.Node {
  const values = queryString.parse(props.location.search);
  const { setLocale } = React.useContext(I18nContext);

  if (values.lang && getActiveLocale() !== values.lang.toLowerCase()) {
    setLocale(values.lang.toLowerCase());
  }

  return (
    <>
      <div className="home-page">
        {/* Video banner */}
        <Section1 />
        <div className="home-content">
          <span id="home-bg" />
          <Layout.Common noFooter>
            {/* more introduction info */}
            <Section2 />

            {/* Areas of cooperation */}
            <Section3 />

            {/* How it works */}
            <Section4 />

            {/* Pricing */}
            <Section5 />

            {/* Quotes carousel */}
            <Quotes />

            <JoinBanner className="mt-5" context="home" />
          </Layout.Common>
        </div>

        {/* Image footer */}
        <div className="footer text-center">
          <Container>
            <img src={footerBg} alt="footer-bg" className="img-fluid" />
          </Container>
        </div>
      </div>

      <Footer sticky />
    </>
  );
}
