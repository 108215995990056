// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import BBCode from '_components/BBCode';
import Link from '../../../_components/Link';
import Icon from '../../../_components/Icon';

// How it works
export default function Section4(): React.Node {
  return (
    <div className="home-s4 mt-5" id="how-it-works">
      {/* Title */}
      <div className="d-flex flex-column p5 mb-5">
        <h2 className="page-heading">
          <I18n id="title" d='How it [span class="text-primary"]works[/span]'>
            {({ value }) => <BBCode>{value}</BBCode>}
          </I18n>
        </h2>
        <span className="text-muted">
          <I18n id="subtitle" d="start cooperation in 3 easy steps" />
        </span>
      </div>

      {/* Cards */}
      <div className="home-s4-cards">
        <Card className="p-4 d-flex flex-column justify-content-between">
          <span className="d-block text-primary mb-4">
            <I18n id="step1" d="Step 1" />
          </span>
          <h5 className="mb-4 text-strong">
            <Icon name="user" className="text-primary mr-3" />
            <I18n id="step1Title" d="Create a profile" />
          </h5>
          <p className="mb-4">
            <I18n
              d="Add your contact and company details to give your potential partners a way to get in touch with you."
              id="step1Text"
            />
          </p>
          <div>
            <Link component={Button} to="/auth/sign-up">
              <I18n id="step1More" d="Start now" />
            </Link>
          </div>
        </Card>

        <Card className="p-4 d-flex flex-column shadow-none justify-content-between">
          <span className="d-block mb-4">
            <I18n id="step2" d="Step 2" />
          </span>
          <h5 className="mb-4 d-flex text-primary">
            <Icon name="location" className="text-primary" />
          </h5>
          <p className="mb-4 text-strong">
            <I18n id="step2Title" d="Search for opportunities" />
          </p>
          <Link to="/auth/sign-up" className="mb-3">
            <I18n id="step2More" d="Start now" />
          </Link>
        </Card>

        <Card className="p-4 d-flex flex-column shadow-none justify-content-between">
          <span className="d-block mb-4">
            <I18n id="step3" d="Step 3" />
          </span>
          <h5 className="mb-4 d-flex text-primary">
            <Icon name="search" className="text-primary" />
          </h5>
          <p className="mb-4 text-strong">
            <I18n id="step3Title" d="Create a reply" />
          </p>
          <Link to="/auth/sign-up" className="mb-3">
            <I18n id="step3More" d="Start now" />
          </Link>
        </Card>
      </div>
    </div>
  );
}
