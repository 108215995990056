// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import toastr from '../../../../_helpers/toastr';
import type { DealModalTarget } from './EditModeButtons';
import { commitMutationPromise } from '../../../../_helpers/Api';

// -------------------------------------------------------------------------------------------------

export type CloseDealModalContext = {|
  setTarget: (?DealModalTarget) => void,
  target: ?DealModalTarget
|};

const Context = React.createContext<CloseDealModalContext>({
  setTarget: function() {},
  target: null
});

// -------------------------------------------------------------------------------------------------

export default function CloseDealModal(props: { children: React.Node }): React.Node {
  const [target, setTarget] = React.useState(null);

  return (
    <Context.Provider value={{ target, setTarget }}>
      <>
        {props.children}
        <Modal show={!!target} onHide={close(setTarget)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <I18n id="title" d="Close Opportunity" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {target && (
              <I18n
                id="text"
                d="Are you sure to close the Opportunity: '{name}'"
                name={target.summary}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={close(setTarget)}>
              <I18n id="cancelButton" d="Cancel" />
            </Button>
            <Button variant="secondary" onClick={confirm(setTarget, target)}>
              <I18n id="confirmButton" d="Close" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Context.Provider>
  );
}

// -------------------------------------------------------------------------------------------------

function close(setTarget: (?DealModalTarget) => void): () => void {
  return function closeModal(): void {
    setTarget(null);
  };
}

function confirm(setTarget: (?DealModalTarget) => void, target: ?DealModalTarget): () => void {
  return function confirmModal(): void {
    target &&
      commitMutationPromise({
        mutation,
        variables: { dealId: target.id }
      })
        .then(response => ({ response, error: undefined }))
        .catch(error => ({ error }))
        .then(({ error }) => {
          setTarget(null);
          toastr({
            type: error ? 'error' : 'success',
            message: error ? (
              <I18n id="errorResult" d="Opportunity status has not been changed!" />
            ) : (
              <I18n id="successResult" d="Opportunity has been closed" />
            )
          });
        });
  };
}

const mutation = graphql`
  mutation CloseDealModalMutation($dealId: ID!) {
    closeDeal(id: $dealId) {
      id
      status
    }
  }
`;

export { Context };
