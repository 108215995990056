// @flow

import cn from 'classnames';
import * as React from 'react';

// $FlowFixMe
import { ReactComponent as Logo } from '../_assets/images/logo.svg';

export default function Brand(props: { light?: boolean, size?: number }): React.Node {
  const { light, size } = props;
  const height = typeof size === 'number' && size > 0 ? size : 23;
  const width = height * 1;
  return <Logo height={height} width={width} className={cn('logo', { light })} />;
}
