// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';

import type { CompanyFormData } from './_components/CompanyForm';
import type { FileInterface } from 'src/_components/Form/_components/FileInput/FileInput';

import Logger from '_helpers/Logger';
import Alert from 'react-bootstrap/Alert';
import Link from '../../_components/Link';
import toastr from '../../_helpers/toastr';
import Layout from '../../_components/Layout';
import Redirect from 'react-router-dom/Redirect';
import CompanyForm from './_components/CompanyForm';
import uploadFile from '../../_helpers/Api/uploadFile';
import { getRequestOptions } from '../../_components/Form/_components/FileInput/_helpers';
import LoadingContent from '../_components/LoadingContent';
import { Query, commitMutationPromise } from '../../_helpers/Api';
import { getQueryParam, routerHistory } from '../../_helpers/router';
import { Context as IdentityContext } from '_helpers/Identity';

// -------------------------------------------------------------------------------------------------

export default function AccountCompanyPage(props: *): React.Node {
  const [pending, setPending] = React.useState(false);
  const { identity, refreshIdentity } = React.useContext(IdentityContext);

  React.useEffect(() => {
    if (refreshIdentity() && !identity) {
      return <Redirect to="/" />;
    }
  }, []);
  const free = !identity.company.paying;
  const created = identity.company.created;
  const expirationDate = free
    ? new Date(new Date(created).setMonth(new Date(created).getMonth() + 6))
    : null;
  const today = new Date().getTime();

  return (
    <Layout.Common narrow>
      {expirationDate && today > expirationDate.getTime() && (
        <Alert variant="danger" className="d-flex">
          <span>
            <I18n id="accountExpired" d="Validity of your account has expired" />
          </span>

          <Link to="/subscribe" className="ml-auto">
            <I18n id="subscription" d="Subscribe now" />
          </Link>
        </Alert>
      )}
      <h1 className="mb-4 mt-4">
        <I18n id="title" d="My company" />
      </h1>
      <Query name="myCompany">
        {({ loading, error, data }) =>
          loading ? (
            <LoadingContent /> // TODO: form default walue was set -> reset default now its disabled
          ) : (
            <CompanyForm
              defaultValue={idx(data, _ => _.me.company) || {}}
              onSubmit={submitHandler(setPending)}
              disabled={loading || pending}
            />
          )
        }
      </Query>
    </Layout.Common>
  );
}

// -------------------------------------------------------------------------------------------------

function submitHandler(
  setPending: boolean => void
): (null | CompanyFormData, { reset: () => void }) => void {
  return async function handleCompanyFormSubmit(
    data: null | CompanyFormData,
    form: { reset: () => void }
  ): void {
    if (data && data.logo && data.logo.blob) {
      setPending(true);
      const fileEndpoint = (process.env.REACT_APP_API_HOST || '') + '/storage/upload/avatar';
      const filesToAdd: Array<FileInterface> = [];
      filesToAdd.push({
        htmlId: data.logo.htmlId,
        size: data.logo.size,
        name: data.logo.name,
        progress: 0
      });
      const actions: FileList = [];
      actions.push(data.logo.blob);

      await actions.reduce((prev: Promise<*>, file: FileInterface, key: number) => {
        return prev
          .then(_ => {
            // $FlowFixMe
            return uploadFile(fileEndpoint, getRequestOptions(file, filesToAdd[key]));
          })
          .catch(e => {
            toastr({
              type: 'error',
              message: (
                <I18n
                  d='Damn! File "{img}" was not saved!'
                  img={filesToAdd[key].name}
                  id="uploadError"
                />
              )
            });
          })
          .then(response => {
            return new Promise(resolve => {
              const res = idx(response, _ => _.data.file);

              const updated = { ...filesToAdd[key] };
              if (!response || !response.ok || !res) {
                updated.error = response && response.code;
                updated.progress = 0;

                if (['BAD_FILE_EXT', 'MAX_FILE_SIZE'].indexOf(updated.error) === -1) {
                  Logger.error('Error uploadig file', response);
                  updated.error = 'GENERAL_ERROR';
                }

                toastr({
                  type: 'error',
                  message: (
                    <I18n
                      d={{
                        BAD_FILE_EXT: 'File {file} has bad meta type.',
                        MAX_FILE_SIZE: 'File {file} is too big.',
                        GENERAL_ERROR: 'File {file} was not uploaded.'
                      }}
                      file={updated.name}
                      v={updated.error}
                      id="uploadError$"
                    />
                  )
                });
              }

              if (!updated.error) {
                updated.error = false;
              }
              // $FlowFixMe
              updated.url = (res && res.url) || null;
              // $FlowFixMe
              updated.id = (res && res.id) || null;
              data.logo = updated;
              resolve();
            });
          });
      }, Promise.resolve());
    }

    const nextStep = getQueryParam('next-step');

    commitMutationPromise({
      mutation: updateCompanyMutation,
      variables: {
        data: {
          ...data,
          web: data.web && !hasProtocol(data.web) ? 'http://' + data.web : data.web || null,
          categories: data.categories ? data.categories.map(i => i.id) : [],
          state: data.country === 'US' ? data.state : null,
          vat: (data.vat || '').toUpperCase() || null,
          logo: idx(data, _ => _.logo.id) || null,
          id: undefined,
          gps: {
            lng: 1,
            lat: 1,
            ...data.gps
          }
        }
      }
    })
      .then(response => ({ response, error: undefined }))
      .catch(error => ({ error }))
      .then(({ error }) => {
        setPending(false);
        if (!error) {
          form.reset();
        }
        if (nextStep) {
          routerHistory.push(nextStep);
        }

        toastr({
          type: error ? 'error' : 'success',
          message: error ? (
            <I18n id="mutationError" d="Ooops! Company details not saved" />
          ) : (
            <I18n id="mutationSuccess" d="Company details saved" />
          )
        });
      });
  };
}

const hasProtocol = val =>
  typeof val === 'string' && (val.startsWith('http://') || val.startsWith('https://'));

// -------------------------------------------------------------------------------------------------

// TODO: use fragment
const updateCompanyMutation = graphql`
  mutation AccountCompanyPageMutation($data: CompanyInput!) {
    myCompany: updateMyCompany(data: $data) {
      id
      country
      state
      street
      city
      zip
      gps {
        lng
        lat
      }
      name
      vat
      logo {
        id
        size
        name
        url
      }
      web
      info
      specialization
      populated
      certificates
      categories {
        id
        name
      }
      created
      modified
      populated
    }
  }
`;
