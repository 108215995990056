// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import { QueryRenderer } from 'react-relay';

import LoadingContent from '../LoadingContent';
import { LocaleTracker } from '../../../_helpers/Api/Query';
import DealPaginationContainer from './DealPaginationContainer';
import environment from '../../../_helpers/Api/relayEnvironment';

export type DealsFilterType = {
  cooperation: null | Array<string>,
  type: null | 'OFFER' | 'REQUEST',
  category: null | Array<ID>,
  status: null | DealStatus,
  keywords: Array<string>,
  location: null | {
    radius: null | number,
    place: null | Place,
    ignore: boolean,
    open: boolean
  },
  page?: number
};

// -------------------------------------------------------------------------------------------------

const PAGE_SIZE = 10;

export default function Searchresults(props: {
  filterDecorator: (*) => DealsFilterType,
  noResults?: React.ComponentType<*>,
  editMode?: boolean,
  contexRef: *,
  query: *
}): React.Node {
  let location = null;
  const { data } = React.useContext(props.contexRef);
  const filter = props.filterDecorator(data);
  const coords = idx(filter, _ => _.location.place.coords);
  const radius = idx(filter, _ => _.location.radius);
  const page = idx(filter, _ => _.page) || 0;
  const offset = (Math.max(page, 1) - 1) * PAGE_SIZE;
  const { query, noResults, editMode } = props;

  if (
    typeof radius === 'number' &&
    typeof coords === 'object' &&
    coords &&
    coords.lng &&
    coords.lat
  ) {
    location = {
      ...coords,
      radius
    };
  }

  const renderList = React.useCallback(
    function RenderList(p: { error: *, props: *, retry: ?() => void }) {
      const { error, props, retry } = p;

      if (error) {
        return (
          <p>
            <I18n
              d="Ooops! Something went wrong, try to change search criteria or reload page."
              id="errMessage"
            />
          </p>
        );
        // eslint-disable-next-line
      } else if (props && props.search && props.search.results) {
        return (
          <LocaleTracker retry={retry}>
            <DealPaginationContainer
              // eslint-disable-next-line
              results={props.search.results}
              noResults={noResults}
              editMode={editMode}
              first={PAGE_SIZE}
            />
          </LocaleTracker>
        );
      }
      return <LoadingContent />;
    },
    [noResults, editMode]
  );

  return (
    <QueryRenderer
      variables={{
        cooperation: (filter.cooperation && filter.cooperation.filter(i => i !== 'ALL')) || null,
        category: idx(filter, _ => _.category) || null,
        keywords: idx(filter, _ => _.keywords) || null,
        offset: typeof offset === 'number' && offset > 0 ? offset : null,
        status: filter.status || null,
        type: filter.type || null,
        first: PAGE_SIZE || 10,
        location
      }}
      environment={environment}
      render={renderList}
      query={query}
    />
  );
}

// -------------------------------------------------------------------------------------------------
