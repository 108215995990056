// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';

import type { EnumChildren } from './';

export type CooperationType =
  | 'RND_COOPERATION'
  | 'SPARE_CAPACITY'
  | 'SHARE_PROCUREMENT'
  | 'SHARE_ASSETS';

function Cooperation(props: { children?: EnumChildren, v?: string }): React.Node {
  return (
    <I18n
      {...props}
      id="cooperation$"
      d={{
        SPARE_CAPACITY: 'Manufacturing capacity',
        SHARE_PROCUREMENT: 'Shared procurement',
        RND_COOPERATION: 'R&D Cooperation',
        SHARE_ASSETS: 'Shared asset use'
      }}
    />
  );
}

const types: Array<CooperationType> = [
  'RND_COOPERATION',
  'SPARE_CAPACITY',
  'SHARE_PROCUREMENT',
  'SHARE_ASSETS'
];

Cooperation.types = types;

export default Cooperation;
