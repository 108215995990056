// @flow

import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import I18n from '_helpers/I18n';
import Link from '_components/Link';

export default function JoinBanner(props: { className?: string, context?: string }): React.Node {
  const { className, context } = props;
  return (
    <Container
      className={
        'join-banner d-flex flex-column flex-md-row justify-content-between  bg-primary p-5 align-items-center ' +
        (className || '')
      }
    >
      <h4 className="text-center mb-4 mb-md-0 text-md-left text-light font-bolder">
        <I18n
          d="{context, select, other {Start enjoying the benefits of B3B}}"
          context={context}
          id="text"
        />
      </h4>
      <Link component={Button} variant="dark" to="/auth/sign-up">
        <I18n
          d="{context, select, home {Join} other {Try now for free}}"
          context={context}
          id="button"
        />
      </Link>
    </Container>
  );
}
