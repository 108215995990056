/**
 * @flow
 * @relayHash 4e6e27c1bd4efff4fb71b754d2e0c0be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatus = "CLOSED" | "COMPLETED" | "FAILED" | "OPEN" | "PROPOSED" | "%future added value";
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type myContractsQueryVariables = {|
  cooperation?: ?$ReadOnlyArray<DealCooperation>,
  categories?: ?$ReadOnlyArray<string>,
  keywords?: ?$ReadOnlyArray<any>,
  type?: ?DealType,
  offset?: ?number,
  first?: ?number,
|};
export type myContractsQueryResponse = {|
  +myContracts: ?{|
    +edges: $ReadOnlyArray<{|
      +cursor: number,
      +node: {|
        +id: string,
        +status: ?ContractStatus,
        +created: ?any,
        +deal: {|
          +id: string,
          +summary: string,
          +type: DealType,
          +category: {|
            +id: string,
            +name: string,
          |},
          +company: {|
            +id: string,
            +name: ?string,
          |},
          +cooperation: DealCooperation,
        |},
        +consumer: {|
          +id: string,
          +name: ?string,
        |},
      |},
    |}>,
    +total: number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
      +startCursor: ?number,
      +endCursor: ?number,
    |},
  |}
|};
export type myContractsQuery = {|
  variables: myContractsQueryVariables,
  response: myContractsQueryResponse,
|};
*/

/*
query myContractsQuery(
  $cooperation: [DealCooperation!]
  $categories: [ID!]
  $keywords: [Keyword!]
  $type: DealType
  $offset: Int
  $first: Int
) {
  myContracts(cooperation: $cooperation, categories: $categories, keywords: $keywords, type: $type, offset: $offset, first: $first) {
    edges {
      cursor
      node {
        id
        status
        created
        deal {
          id
          summary
          type
          category {
            id
            name
          }
          company {
            id
            name
          }
          cooperation
        }
        consumer {
          id
          name
        }
      }
    }
    total
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'cooperation',
        type: '[DealCooperation!]',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'categories',
        type: '[ID!]',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'keywords',
        type: '[Keyword!]',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'type',
        type: 'DealType',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'offset',
        type: 'Int',
        defaultValue: null
      },
      {
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
        defaultValue: null
      }
    ],
    v1 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null
    },
    v2 = [
      (v1 /*: any*/),
      {
        kind: 'ScalarField',
        alias: null,
        name: 'name',
        args: null,
        storageKey: null
      }
    ],
    v3 = [
      {
        kind: 'LinkedField',
        alias: null,
        name: 'myContracts',
        storageKey: null,
        args: [
          {
            kind: 'Variable',
            name: 'categories',
            variableName: 'categories'
          },
          {
            kind: 'Variable',
            name: 'cooperation',
            variableName: 'cooperation'
          },
          {
            kind: 'Variable',
            name: 'first',
            variableName: 'first'
          },
          {
            kind: 'Variable',
            name: 'keywords',
            variableName: 'keywords'
          },
          {
            kind: 'Variable',
            name: 'offset',
            variableName: 'offset'
          },
          {
            kind: 'Variable',
            name: 'type',
            variableName: 'type'
          }
        ],
        concreteType: 'ContractsConnection',
        plural: false,
        selections: [
          {
            kind: 'LinkedField',
            alias: null,
            name: 'edges',
            storageKey: null,
            args: null,
            concreteType: 'ContractEdge',
            plural: true,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'cursor',
                args: null,
                storageKey: null
              },
              {
                kind: 'LinkedField',
                alias: null,
                name: 'node',
                storageKey: null,
                args: null,
                concreteType: 'Contract',
                plural: false,
                selections: [
                  (v1 /*: any*/),
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'status',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'created',
                    args: null,
                    storageKey: null
                  },
                  {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'deal',
                    storageKey: null,
                    args: null,
                    concreteType: 'Deal',
                    plural: false,
                    selections: [
                      (v1 /*: any*/),
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'summary',
                        args: null,
                        storageKey: null
                      },
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'type',
                        args: null,
                        storageKey: null
                      },
                      {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'category',
                        storageKey: null,
                        args: null,
                        concreteType: 'NACECategory',
                        plural: false,
                        selections: (v2 /*: any*/)
                      },
                      {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'company',
                        storageKey: null,
                        args: null,
                        concreteType: 'Company',
                        plural: false,
                        selections: (v2 /*: any*/)
                      },
                      {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'cooperation',
                        args: null,
                        storageKey: null
                      }
                    ]
                  },
                  {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'consumer',
                    storageKey: null,
                    args: null,
                    concreteType: 'Company',
                    plural: false,
                    selections: (v2 /*: any*/)
                  }
                ]
              }
            ]
          },
          {
            kind: 'ScalarField',
            alias: null,
            name: 'total',
            args: null,
            storageKey: null
          },
          {
            kind: 'LinkedField',
            alias: null,
            name: 'pageInfo',
            storageKey: null,
            args: null,
            concreteType: 'PageInfo',
            plural: false,
            selections: [
              {
                kind: 'ScalarField',
                alias: null,
                name: 'hasNextPage',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'hasPreviousPage',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'startCursor',
                args: null,
                storageKey: null
              },
              {
                kind: 'ScalarField',
                alias: null,
                name: 'endCursor',
                args: null,
                storageKey: null
              }
            ]
          }
        ]
      }
    ];
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'myContractsQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    operation: {
      kind: 'Operation',
      name: 'myContractsQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: (v3 /*: any*/)
    },
    params: {
      operationKind: 'query',
      name: 'myContractsQuery',
      id: null,
      text:
        'query myContractsQuery(\n  $cooperation: [DealCooperation!]\n  $categories: [ID!]\n  $keywords: [Keyword!]\n  $type: DealType\n  $offset: Int\n  $first: Int\n) {\n  myContracts(cooperation: $cooperation, categories: $categories, keywords: $keywords, type: $type, offset: $offset, first: $first) {\n    edges {\n      cursor\n      node {\n        id\n        status\n        created\n        deal {\n          id\n          summary\n          type\n          category {\n            id\n            name\n          }\n          company {\n            id\n            name\n          }\n          cooperation\n        }\n        consumer {\n          id\n          name\n        }\n      }\n    }\n    total\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n  }\n}\n',
      metadata: {}
    }
  };
})();
// prettier-ignore
(node/*: any*/).hash = '648772bf18fb18779266794016d1216a';
module.exports = node;
