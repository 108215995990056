// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import Button from 'react-bootstrap/Button';

import type { OnChangeEvent } from '../../';

import Link from '../../../Link';
import Icon from '../../../Icon';
import Logger from '_helpers/Logger';
import LimitText from '../../../LimitText';
import toastr from '../../../../_helpers/toastr';
import { htmlId, getRequestOptions } from './_helpers';
import uploadFile from '../../../../_helpers/Api/uploadFile';

// -------------------------------------------------------------------------------------------------

export type FileInterface = {
  progress?: number,
  htmlId?: string,
  size: number,
  name: string,
  url?: string,
  error?: any,
  id?: ID
};

export type FileInputProps = {|
  onChange: (OnChangeEvent<FileInterface | Array<FileInterface>>) => void,
  value: null | FileInterface | Array<FileInterface>,
  accept: 'avatar' | 'deal_attachment',
  multiple?: number | boolean,
  disabled?: boolean,
  name: string
|};

// -------------------------------------------------------------------------------------------------

const acceptMap = {
  avatar: ['image/jpg', 'image/jpeg', 'image/png'],
  deal_attachment: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']
};

export default class FileInput extends React.PureComponent<FileInputProps> {
  inputRef = React.createRef<HTMLInputElement>();

  render(): React.Node {
    const { multiple, value, accept, onChange, name, disabled } = this.props;
    const renderList = [...(value ? (Array.isArray(value) ? value : [value]) : [])];

    if (!accept || !acceptMap[accept]) {
      throw new Error('unknown file target type');
    }

    return (
      <div className="form-conrol file">
        <input
          style={{ position: 'fixed', left: -9999, top: -9999, opacity: 0 }}
          accept={accept ? acceptMap[accept].join(',') : null}
          multiple={multiple ? 'multiple' : null}
          onChange={this.handleSelect}
          ref={this.inputRef}
          name="file"
          type="file"
        />

        <div className="file-list">
          {renderList.map((file: FileInterface, key: number) => {
            const showProgress =
              !file.error && !file.id && typeof file.progress === 'number' && file.progress > -1
                ? file.progress
                : -1;

            return (
              <div
                className="file-item d-flex align-items-center"
                key={'file-input-' + (file.id || file.htmlId || Date.now())}
              >
                {file.url ? (
                  <LimitText
                    component={Link}
                    style={{
                      textDecoration: file.error ? 'line-through' : null
                    }}
                    to={file.url}
                    max="60%"
                    blank
                  >
                    {file.name}
                  </LimitText>
                ) : (
                  <LimitText
                    style={{ textDecoration: file.error ? 'line-through' : null }}
                    title={file.name}
                    max="60%"
                  >
                    {file.name}
                  </LimitText>
                )}

                <small className="ml-2 mr-auto">({(file.size / 1024).toFixed(2)}kB)</small>

                <div className="d-flex align-items-center" id={file.htmlId}>
                  {showProgress > -1 ? (
                    `${showProgress}%`
                  ) : (
                    <Link
                      className="d-flex ml-2"
                      onClick={requestRemove(onChange, name, value, key)}
                    >
                      <Icon name="close" />
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="align-items-center">
          <Button
            className="mr-3 text-nowrap"
            onClick={this.handleOpen}
            disabled={disabled}
            variant="dark"
            size="sm"
          >
            <I18n
              d="Choose {multiple, plural, =1 {File} other {File(s)}}"
              multiple={parseInt(multiple, 10) || 1}
              id="selectButton"
            />
          </Button>

          <small className="d-inline-block mt-2 mb-0">
            {multiple && <I18n id="multipleFiles" d="Select up to {max} files." max={multiple} />}{' '}
            {accept && acceptMap[accept] && (
              <I18n
                list={acceptMap[accept].map(i => i.split('/').pop()).join(', ')}
                d="Enabled types: {list}"
                id="restrectedFormat"
              />
            )}
          </small>
        </div>
      </div>
    );
  }

  // // --------------------------------------------------------------------------------------------

  handleOpen = () => {
    const ref = this.inputRef && this.inputRef.current;
    if (ref) {
      ref.value = '';
      ref.click();
    }
  };

  // //  -------------------------------------------------------------------------------------------

  handleSelect = (e: SyntheticEvent<*>) => {
    const newFiles: ?FileList = e.currentTarget && e.currentTarget.files;

    const max = typeof this.props.multiple === 'number' ? this.props.multiple : 0;
    if (!newFiles || newFiles.length === 0) {
      return;
    }

    const filesToAdd: Array<FileInterface> = [];

    if (newFiles && newFiles.length > 0) {
      const actions = [];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        actions.push(file);
        filesToAdd.push({
          error: !!(max && actions.length > max),
          htmlId: htmlId(),
          size: file.size,
          name: file.name,
          progress: 0
        });
      }

      this.props.onChange({
        name: this.props.name,
        // $FlowFixMe
        value: this.props.multiple ? [...(this.props.value || []), ...filesToAdd] : filesToAdd[0]
      });

      const fileEndpoint =
        (process.env.REACT_APP_API_HOST || '') + '/storage/upload/' + this.props.accept;

      actions.reduce((prev: Promise<*>, file: FileInterface, key: number) => {
        return prev
          .then(_ => {
            if (!file || filesToAdd[key].error || filesToAdd[key].progress !== 0) {
              return Promise.resolve();
            }
            // $FlowFixMe
            return uploadFile(fileEndpoint, getRequestOptions(file, filesToAdd[key]));
          })
          .catch(e => {
            toastr({
              type: 'error',
              message: (
                <I18n
                  d='Damn! File "{img}" was not saved!'
                  img={filesToAdd[key].name}
                  id="uploadError"
                />
              )
            });
          })
          .then(response => {
            return new Promise(resolve => {
              const { value, name, onChange, multiple } = this.props;
              const res = idx(response, _ => _.data.file);

              const updated = { ...filesToAdd[key] };
              if (!response || !response.ok || !res) {
                updated.error = response && response.code;
                updated.progress = 0;

                if (['BAD_FILE_EXT', 'MAX_FILE_SIZE'].indexOf(updated.error) === -1) {
                  Logger.error('Error uploadig file', response);
                  updated.error = 'GENERAL_ERROR';
                }

                toastr({
                  type: 'error',
                  message: (
                    <I18n
                      d={{
                        BAD_FILE_EXT: 'File {file} has bad meta type.',
                        MAX_FILE_SIZE: 'File {file} is too big.',
                        GENERAL_ERROR: 'File {file} was not uploaded.'
                      }}
                      file={updated.name}
                      v={updated.error}
                      id="uploadError$"
                    />
                  )
                });
              }
              // $FlowFixMe
              updated.url = (res && res.url) || '';
              // $FlowFixMe
              updated.id = (res && res.id) || null;
              // $FlowFixMe
              const val = this.props.multiple ? [...(value || [])] : updated;

              if (multiple) {
                // $FlowFixMe
                const i = val.findIndex(j => j === filesToAdd[key]);
                if (i > -1) {
                  val[i] = updated;
                }
              }

              const event: OnChangeEvent<FileInterface | Array<FileInterface>> = {
                value: val,
                name
              };

              resolve();
              onChange(event);
            });
          });
      }, Promise.resolve());
    }
  };
}

// -------------------------------------------------------------------------------------------------

function requestRemove(
  onChange: (OnChangeEvent<FileInterface | Array<FileInterface>>) => void,
  name: string,
  value: null | FileInterface | Array<FileInterface>,
  index: number
): () => void {
  return function() {
    if (!value) {
      return;
    }

    const update = { name, value };

    if (Array.isArray(value)) {
      update.value = [...value];
      update.value.splice(index, 1);
    } else {
      update.value = null;
    }

    onChange && onChange(update);
  };
}
