// @flow

import idx from 'idx';
import I18n from '_helpers/I18n';
import * as React from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';

import type { OnChangeEvent } from '../../';
import type { MessagingInputValue } from './MessagingInput';

import Form from '../../';
import Icon from '../../../Icon';
import MessagingInput from './MessagingInput';

// -------------------------------------------------------------------------------------------------

export type MessagingProps = {
  onChange: (OnChangeEvent<{ [number]: MessagingInputValue }>) => void,
  value: null | { [number]: MessagingInputValue },
  isInvalid?: boolean,
  name: string,
  max?: number
};

// -------------------------------------------------------------------------------------------------

export default class Messaging extends React.Component<MessagingProps> {
  render(): React.Node {
    const { max, name, value, isInvalid } = this.props;
    // $FlowFixMe
    const items = value && typeof value === 'object' ? value : { 0: { type: null, value: null } };
    const classes = classNames('messaging-input', { 'is-invalid': isInvalid });

    return (
      <div
        className={classes}
        style={{
          borderBottomWidth: 0,
          background: 'none',
          paddingRight: 0,
          height: 'auto',
          paddingLeft: 0
        }}
      >
        {Object.keys(items).map((item, key) => {
          return (
            // $FlowFixMe
            <Form.Field
              name={name + '.' + key.toString()}
              component={MessagingInput}
              className="mb-2"
              // TODO: key
              key={key}
            />
          );
        })}

        {!max ||
          max < 0 ||
          (Object.keys(items).length < max && (
            <Button
              variant="outline-secondary dashed mt-2"
              onClick={this.getAddHandler}
              size="sm"
              block
            >
              <Icon name="plus" className="mr-2" />
              <I18n id="addItem" d="Add method" />
            </Button>
          ))}
      </div>
    );
  }

  getAddHandler = () => {
    const value = { ...(this.props.value || {}) };
    value[Object.keys(value).length] = { type: null, value: null };
    this.props.onChange && this.props.name && this.props.onChange({ value, name: this.props.name });
  };

  shouldComponentUpdate(nextProps: MessagingProps): boolean {
    const current = Object.keys(idx(this.props, _ => _.value) || {}).length;
    const next = Object.keys(idx(nextProps, _ => _.value) || {}).length;
    return current !== next || this.props.name !== nextProps.name;
  }
}

// -------------------------------------------------------------------------------------------------
