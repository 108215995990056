// @flow

import * as React from 'react';
import I18n from '_helpers/I18n';

import PricingCards from '../../_components/PricingCards';
import { routerHistory } from '../../../_helpers/router';

// Pricing
export default function Section5(): React.Node {
  return (
    <div className="home-s5" id="pricing">
      <div className="d-flex flex-column align-items-center p5 mb-5">
        <h2 className="page-heading">
          <I18n id="title" d="Pricing" />
        </h2>
        <span className="text-muted">
          <I18n id="subtitle" d="Some more text explaining pricing" />
        </span>
      </div>

      <PricingCards buttonLabel={StartNowLabel} buttonAction={startNow} />
    </div>
  );
}

function StartNowLabel() {
  return <I18n id="startNowButton" d="Start now" />;
}

function startNow() {
  routerHistory.push('/subscribe');
}
