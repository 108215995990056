// @flow

import type { QueryRendererProps } from 'react-relay';

import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import type { myCompanyQueryResponse } from './__generated__/myCompanyQuery.graphql';

import env from '../relayEnvironment';

export default function myCompany(props: QueryRendererProps<myCompanyQueryResponse>): React.Node {
  return <QueryRenderer render={props.children} environment={env} query={query} />;
}

const query = graphql`
  query myCompanyQuery {
    me {
      id
      company {
        id
        name
        vat
        logo {
          id
          name
          url
          size
        }
        info
        web
        specialization
        certificates
        categories {
          id
          name
        }
        country
        state
        street
        city
        zip
        gps {
          lng
          lat
        }
      }
    }
  }
`;

export { query };
