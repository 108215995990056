// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import Link from '../../../_components/Link';
import DateTime from '../../../_components/DateTime';
import { Identity } from '../../../_helpers/Identity';
import CreateContractModal from './CreateContractModal';

// -------------------------------------------------------------------------------------------------

// TODO: use GQL fragment
export default function ContractListItem(props: { contract: * }): React.Node {
  const contract = props.contract;
  const consumer = contract && contract.consumer;

  if (!consumer || !contract) {
    return null;
  }

  return (
    <Identity>
      {({ identity }) => (
        <div className="contract-list-item p-3">
          <Row>
            <Col>
              {!contract.seen && (
                <Badge variant="warning" className="mr-2">
                  <I18n id="unseen" d="NEW" />
                </Badge>
              )}
              {contract.status !== 'PROPOSED' || (identity && identity.company.paying) ? (
                <Link to={`/company/${consumer.id}`}>
                  <strong>{consumer.name}</strong>
                </Link>
              ) : (
                <strong className="font-italic">
                  <I18n id="companyNameSubscribe" d="Subscribe to see company name" />
                </strong>
              )}
            </Col>
            <Col className="text-right">
              {contract.recorded && <DateTime.Date value={contract.recorded} />}
            </Col>
          </Row>
          <Row>
            <Col>
              <Row noGutters>
                <I18n id="memberSince" d="Member since: " />
                <DateTime.Date value={consumer.created} />
              </Row>
              <Row noGutters>
                <I18n
                  successfull={consumer.succeededContracts}
                  d="Successful contracts: {successfull}"
                  id="successfullContracts"
                />
              </Row>
              <Row noGutters>
                <I18n
                  total={consumer.totalContracts}
                  d="Total replies: {total}"
                  id="totalContracts"
                />
              </Row>
            </Col>
            <Col className="text-right d-flex align-items-end">
              {contract.status === 'PROPOSED' ? (
                identity && identity.company.paying ? (
                  <CreateContractModal.Consumer>
                    {({ setTarget }) => (
                      <Button
                        onClick={createContract(setTarget, contract)}
                        variant="success"
                        className="ml-auto"
                        size="sm"
                      >
                        <I18n id="createButton" d="Create Reply" />
                      </Button>
                    )}
                  </CreateContractModal.Consumer>
                ) : (
                  <Link
                    className="ml-auto"
                    component={Button}
                    variant="warning"
                    to="/subscribe"
                    size="sm"
                  >
                    <I18n id="subscribeButton" d="Subscribe" />
                  </Link>
                )
              ) : (
                <Link
                  to={`/account/my-contracts/update/${contract.id}/edit`}
                  component={Button}
                  className="ml-auto"
                  size="sm"
                >
                  <I18n id="updateButton" d="Update Reply" />
                </Link>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Identity>
  );
}

function createContract(setter: (*) => void, target: *): () => void {
  return function openCreateContractModel() {
    setter && target && setter(target);
  };
}
