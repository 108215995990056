// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Link from './Link';

export type PaginationProps = {
  onRequestPage: (page: number) => void,
  pages: number,
  page: number
};

function Pagination(props: PaginationProps): React.Node {
  const { onRequestPage, pages, page } = props;

  const action = React.useCallback(
    (newPage: number) => () => {
      onRequestPage && onRequestPage(newPage);
    },
    [onRequestPage]
  );

  if (!pages || pages < 1) {
    return null;
  }

  return (
    <Row className="mt-5">
      <Col>
        {page > 1 && (
          <Link onClick={action(page - 1)}>
            <I18n id="prevPage" d="&laquo; Prev" />
          </Link>
        )}
      </Col>
      <Col className="text-center">
        {page > 2 && (
          <>
            <Link onClick={action(1)} className="ml-1 mr-1">
              {1}
            </Link>
            {page > 3 && <>...</>}
          </>
        )}
        {page > 1 && (
          <Link onClick={action(page - 1)} className="ml-1 mr-1">
            {page - 1}
          </Link>
        )}
        <span className="ml-1 mr-1">{page}</span>
        {page < pages && (
          <Link onClick={action(page + 1)} className="ml-1 mr-1">
            {page + 1}
          </Link>
        )}
        {pages > page + 1 && (
          <>
            {page + 2 <= pages && <>...</>}
            <Link onClick={action(pages)} className="ml-1 mr-1">
              {pages}
            </Link>
          </>
        )}
      </Col>
      <Col className="text-right">
        {pages > page && (
          <Link onClick={action(page + 1)}>
            <I18n id="nextPage" d="Next &raquo;" />
          </Link>
        )}
      </Col>
    </Row>
  );
}

export default Pagination;
