// @flow

import idx from 'idx';
import * as React from 'react';

import type { OnChangeEvent } from '../../_components/Form';
import type { TypeaheadOptions } from '../../_components/Form/_components/Typeahead';

import { Query } from '../../_helpers/Api';
import { Typeahead } from '../../_components/Form';

// -------------------------------------------------------------------------------------------------

export type CategoryAutocompleteProps = {
  onChange: (OnChangeEvent<NACECategory>) => void,
  value: null | NACECategory,
  name: string,
  id?: string
};

// -------------------------------------------------------------------------------------------------

export default function CategoryAutocomplete(props: CategoryAutocompleteProps): React.Node {
  const [search, setSearch] = React.useState('');
  const { value, onChange, id, ...rest } = props;

  return (
    <Query name="searchCategory" variables={{ search }}>
      {({ loading, data }) => (
        <Typeahead
          {...rest}
          value={value ? [{ label: value.name, value: value.id }] : []}
          id={id || 'search-category-' + Date.now()}
          onChange={onChangeHandler(onChange)}
          options={decorateOptions(data)}
          className="control-search"
          onSearch={setSearch}
          loading={loading}
        />
      )}
    </Query>
  );
}

// -------------------------------------------------------------------------------------------------

function onChangeHandler(
  onChange: (OnChangeEvent<NACECategory>) => void
): (OnChangeEvent<TypeaheadOptions>) => void {
  return function handleOnChange(e: OnChangeEvent<TypeaheadOptions>): void {
    const { name, value } = e;
    onChange({
      name,
      value:
        value && value[0]
          ? { id: value[0].value, name: typeof value[0].label === 'string' ? value[0].label : '' }
          : null
    });
  };
}

// -------------------------------------------------------------------------------------------------

function decorateOptions(data: *): TypeaheadOptions {
  return idx(data, _ => _.search.categories) || [];
}
