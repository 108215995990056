// @flow

import op from 'object-path';
import * as React from 'react';
import I18n from '_helpers/I18n';
import ReactDOMServer from 'react-dom/server';
import InputGroup from 'react-bootstrap/InputGroup';

import type { OnChangeEvent } from '../../types';
import type { SelectOption } from '../SelectInput';

import Enum from '../../../Enum';
import TextInput from '../TextInput';
import SelectInput from '../SelectInput';
import { FormConsumer } from '../../Form';

// -------------------------------------------------------------------------------------------------

export type MessagingInputValue = {|
  value: null | string,
  type: null | string
|};

export type MessagingInputProps = {
  onChange: (OnChangeEvent<string>) => void,
  value: null | MessagingInputValue,
  isInvalid?: boolean,
  className?: string,
  name: string
};

// -------------------------------------------------------------------------------------------------

export default function MessagingInput(props: MessagingInputProps): React.Node {
  const { onChange, className, isInvalid, name, value } = props;
  const inputValue = value;

  return (
    <>
      <InputGroup className={isInvalid ? 'is-invalid' : ''}>
        <I18n id="typePlaceholder" d="Select type...">
          {({ value }) => {
            const placeholder = value;
            return (
              <FormConsumer>
                {({ data }) => {
                  let parentName = name.split('.');
                  const currentIndex = parseInt(parentName.pop(), 10);
                  parentName = parentName.join('.');
                  const allItems: { [string]: MessagingInputValue } =
                    op.get(data, parentName) || {};

                  const disabled = Object.keys(allItems).reduce(
                    (list: Array<string>, index: string) => {
                      if (parseInt(index, 10) !== currentIndex && allItems[index].type) {
                        list.push(allItems[index].type);
                      }
                      return list;
                    },
                    []
                  );

                  const options = Enum.MessagingType.types.reduce(
                    (options: Array<SelectOption>, type: string) => {
                      disabled.indexOf(type) === -1 &&
                        options.push({
                          value: type,
                          label: ReactDOMServer.renderToString(<Enum.MessagingType v={type} />)
                        });
                      return options;
                    },
                    []
                  );

                  return (
                    <SelectInput
                      value={inputValue ? inputValue.type : null}
                      placeholder={placeholder}
                      className={className}
                      name={name + '.type'}
                      onChange={onChange}
                      options={options}
                    />
                  );
                }}
              </FormConsumer>
            );
          }}
        </I18n>

        <I18n
          id="valuePlaceholder"
          d="value..."
          component={TextInputWithPlacehoder}
          inputValue={inputValue ? inputValue.value : null}
          name={name + '.value'}
          style={{ zIndex: 1 }}
          placeholder={value}
          onChange={onChange}
        />
      </InputGroup>
    </>
  );
}

// -------------------------------------------------------------------------------------------------

const TextInputWithPlacehoder = (props: *) => {
  // eslint-disable-next-line
  const { value, inputValue, id, component, ...rest } = props;
  return <TextInput {...rest} placeholder={value} value={inputValue} />;
};
