// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import type { DealModalTarget } from './EditModeButtons';

import toastr from '../../../../_helpers/toastr';
import { commitMutationPromise } from '../../../../_helpers/Api';

// -------------------------------------------------------------------------------------------------

export type DeleteModalContext = {|
  setTarget: (?DealModalTarget) => void,
  target: ?DealModalTarget
|};

const Context = React.createContext<DeleteModalContext>({
  setTarget: function() {},
  target: null
});

// -------------------------------------------------------------------------------------------------

export default function DeleteDealModal(props: { children: React.Node }): React.Node {
  const [target, setTarget] = React.useState(null);

  return (
    <Context.Provider value={{ target, setTarget }}>
      <>
        {props.children}
        <Modal show={!!target} onHide={close(setTarget)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <I18n id="title" d="Delete Opportunity" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {target && (
              <I18n
                d="Are you sure to delete the Opportunity: '{name}'"
                name={target.summary}
                id="text"
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={close(setTarget)}>
              <I18n id="cancelButton" d="Cancel" />
            </Button>
            <Button variant="danger" onClick={confirm(setTarget, target)}>
              <I18n id="confirmButton" d="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Context.Provider>
  );
}

// -------------------------------------------------------------------------------------------------

function close(setTarget: (?DealModalTarget) => void): () => void {
  return function closeModal(): void {
    setTarget(null);
  };
}

function confirm(setTarget: (?DealModalTarget) => void, target: ?DealModalTarget): () => void {
  return function confirmModal(): void {
    target &&
      commitMutationPromise({
        mutation,
        variables: { dealId: target.id },
        configs: [{ type: 'NODE_DELETE', deletedIDFieldName: 'id' }]
      })
        .then(response => ({ response, error: undefined }))
        .catch(error => ({ error }))
        .then(({ error }) => {
          setTarget(null);
          toastr({
            type: error ? 'error' : 'success',
            message: error ? (
              <I18n id="errorResult" d="Opportunity cannot be deleted" />
            ) : (
              <I18n id="successResult" d="Opportunity has been removed" />
            )
          });
        });
  };
}

const mutation = graphql`
  mutation DeleteDealModalMutation($dealId: ID!) {
    deleteDeal(id: $dealId) {
      id
    }
  }
`;

export { Context };
