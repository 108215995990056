// @flow

import I18n from '_helpers/I18n';
import * as React from 'react';
import { graphql } from 'react-relay';

import type { OnChangeEvent } from '../../';
import type { FileInterface } from '../FileInput/FileInput';

import Icon from '../../../Icon';
import Pencil from '../../../../_assets/images/pencil.svg';
import Undo from '../../../../_assets/images/undo.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toastr from '../../../../_helpers/toastr';

import { commitMutationPromise } from '../../../../_helpers/Api';
import { htmlId } from '../FileInput/_helpers';
import LoadingContent from '../../../../_pages/_components/LoadingContent'; // TODO: do not import from pages

// -------------------------------------------------------------------------------------------------

export type ImageInputProps = {|
  onChange: (OnChangeEvent<FileInterface>) => void,
  value: null | FileInterface,
  placeholder?: string,
  disabled?: boolean,
  name: string
|};

// -------------------------------------------------------------------------------------------------

const acceptList = ['image/jpg', 'image/jpeg', 'image/png'];

export default class FileInput extends React.PureComponent<ImageInputProps> {
  inputRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = { modal: false, companyLogo: null };
    if (props.value) {
      this.state.companyLogo = props.value;
    }
  }

  render(): React.Node {
    const { value, disabled, placeholder } = this.props;
    if (value && value.id) {
      this.setState({ companyLogo: value });
    }
    const img = value && !value.error && value.url;

    return (
      <>
        <div className="form-conrol image">
          <input
            style={{ position: 'fixed', left: -9999, top: -9999, opacity: 0 }}
            onChange={this.handleSelect}
            accept={acceptList}
            ref={this.inputRef}
            name="file"
            type="file"
          />

          <div
            className="image-wrapper"
            onClick={this.handleOpen}
            title={placeholder}
            style={{
              backgroundImage: img ? `url(${img})` : undefined
            }}
            disabled={disabled}
          >
            {value && !value.error ? (
              value.url ? null : (
                <LoadingContent />
              )
            ) : (
              <span className="placeholder">{placeholder || 'Image'}</span>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-around image-buttons">
          <div
            className="d-flex justify-content-center col-6 pt-2 pb-2 icon-div"
            onClick={this.handleOpen}
          >
            <img src={Pencil} alt="Edit logo" />
          </div>
          <div
            className="d-flex justify-content-center col-6 pt-2 pb-2 icon-div"
            onClick={this.handleRemove}
          >
            {value && !value.error && value.blob ? (
              <img src={Undo} alt="Undo edit logo" />
            ) : (
              <Icon name="trash-can" />
            )}
          </div>
        </div>
        <Modal show={this.state.modal} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <I18n id="modalTitle" d="Delete the company logo" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modal && (
              <I18n id="modalText" d="Are you sure to permanently delete the company logo?" />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.handleModalClose}>
              <I18n id="cancelButton" d="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.handleModalApprove}>
              <I18n id="confirmButton" d="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  // // --------------------------------------------------------------------------------------------

  handleOpen = () => {
    const ref = this.inputRef && this.inputRef.current;
    if (ref) {
      ref.value = '';
      ref.click();
    }
  };

  handleRemove = () => {
    if (this.props.value && this.props.value.id && this.props.value.name && this.props.value.url) {
      this.setState({ modal: true });
    } else if (this.props.value && this.props.value.url) {
      this.props.onChange({
        name: this.props.name,
        value: this.state.companyLogo
      });
    } else {
    }
  };

  // //  -------------------------------------------------------------------------------------------

  handleSelect = (e: SyntheticEvent<*>) => {
    if (acceptList.find(element => element === e.currentTarget.files[0].type) !== undefined) {
      const val = {
        name: e.currentTarget.files[0].name,
        size: e.currentTarget.files[0].size,
        htmlId: htmlId(),
        url: URL.createObjectURL(e.currentTarget.files[0]),
        lastModified: e.currentTarget.files[0].lastModified,
        lastModifiedDate: e.currentTarget.files[0].lastModifiedDate,
        webKitRelativePath: e.currentTarget.files[0].webKitRelativePath,
        type: e.currentTarget.files[0].type,
        blob: e.currentTarget.files[0]
      };
      this.props.onChange({
        name: this.props.name,
        value: val
      });
    } else {
      toastr({
        type: 'error',
        message: (
          <I18n
            d="File {file} has bad meta type."
            file={e.currentTarget.files[0].name}
            id="typeError"
          />
        )
      });
    }
  };

  handleModalClose = () => {
    this.setState({ modal: false });
  };

  handleModalApprove = () => {
    this.props.onChange({
      name: this.props.name,
      value: null
    });
    commitMutationPromise({
      mutation,
      variables: {},
      configs: [{}]
    }).then(response => ({ response, error: undefined }));
    this.setState({ modal: false, companyLogo: null });
  };
}

// mutation for deleting logo - ImageInputMutation -> mutation must have the same name as file where defined
const mutation = graphql`
  mutation ImageInputMutation {
    deleteLogo {
      logo {
        url
      }
    }
  }
`;
